import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Course } from '../../../common/models/course/courses';
import { CourseSettingsService } from '../../../services/course-settings.service';
import { SettingsEntity } from '../../../common/models/settings/settings-entity';
import { ToastService } from '../../../services/toast.service';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { CourseStepsComponent } from '../course-steps/course-steps.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { CoursesService } from '../../../services/courses.service';
import { ModalService } from '../../../services/modal.service';
import { CourseSubscribeModalComponent } from '../course-subscribe-modal/course-subscribe-modal.component';
import { CommonService } from '../../../services/common.service';
import { NavigationExtras } from '@angular/router';
import {
  faExclamation,
  faCertificate,
  faCheck,
  faCheckSquare,
  faXmark,
  faDollarSign,
  faEdit,
  faInfo,
  faLaptop,
  faList,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-course-detail-content',
    imports: [
        CommonModule,
        TranslateModule,
        FontAwesomeModule,
        SkeletonDirective,
        CourseStepsComponent,
    ],
    templateUrl: './course-detail-content.component.html',
    styleUrls: ['./course-detail-content.component.scss']
})
export class CourseDetailContentComponent implements OnChanges {
  @Input() course: Course;
  @Input() loadingDetails = true;

  public certificateSetting: string;
  public courseSettings: SettingsEntity[];
  public courseTypeSetting: string;
  public priceSetting: string;
  public supportedInterfaceSetting: string;

  public hasShowGoToClassroom: boolean;
  public hasShowGoToSubscribe: boolean;
  public hasShowInvitedOnly: boolean;
  public isExpiredInvitation: boolean;

  public alertIcon = faExclamation;
  public certificateIcon = faCertificate;
  public checkIcon = faCheck;
  public checkSquareIcon = faCheckSquare;
  public closeIcon = faXmark;
  public dollarIcon = faDollarSign;
  public editIcon = faEdit;
  public infoIcon = faInfo;
  public laptopIcon = faLaptop;
  public listIcon = faList;
  public targetIcon = faCrosshairs;

  constructor(
    public _authService: AuthenticationService,
    public _commonService: CommonService,
    private _courseService: CoursesService,
    private _courseSettingsService: CourseSettingsService,
    private _modalService: ModalService,
    private _toastService: ToastService
  ) {}

  public async ngOnChanges(): Promise<void> {
    if (this.course) {
      if (this._authService.isLogged) {
        await this.getCourseSettings();
      }
      this._handleCourseAccess();
    }
  }

  public async _handleCourseAccess(): Promise<void> {
    this.isExpiredInvitation = await this._courseService.isExpired(this.course);
    this.hasShowGoToClassroom = await this._courseService.hasShowGoToClassroom(
      this.course
    );
    this.hasShowGoToSubscribe = await this._courseService.hasShowGoToSubscribe(
      this.course
    );
    this.hasShowInvitedOnly = await this._courseService.hasShowInvitedOnly(
      this.course
    );
  }

  public async getCourseSettings(): Promise<void> {
    try {
      const courseSettings = await this._courseSettingsService.getSettings(
        this.course.slug
      );

      this.courseTypeSetting = courseSettings.find(
        (s) => s.type === 'COURSE_TYPE'
      ).value;
      this.supportedInterfaceSetting = courseSettings.find(
        (s) => s.type === 'SUPPORTED_INTERFACE'
      ).value;
      this.certificateSetting = courseSettings.find(
        (s) => s.type === 'ISSUE_CERTIFICATE'
      ).value;
      this.priceSetting = courseSettings.find((s) => s.type === 'PRICE').value;
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async openSubscriptionModal(): Promise<void> {
    const modalRef = await this._modalService.openModal(
      CourseSubscribeModalComponent,
      { courseId: this.course.id, courseSlug: this.course.slug }
    );

    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.goToCourseStep(this.course.currentStep.id);
      }
    });
  }

  public goToCourseStep(stepId: number): void {
    const extras: NavigationExtras = {
      queryParams: {
        courseId: this.course.id,
      },
    };
    this._commonService.navigate(
      ['course', this.course.slug, 'step', stepId as unknown as string],
      extras
    );
  }
}
