import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonService } from '../../../services/common.service';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-progress-bar',
    imports: [CommonModule, MatProgressBarModule, FontAwesomeModule],
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() public fileName: string;
  @Input() public progressValue: number;

  public color: string;
  public infoIcon = faCircleInfo;

  constructor(public _commonService: CommonService) {}
}
