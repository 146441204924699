import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Tag } from '../../../common/models/tags/tag';

@Component({
    selector: 'mec-tags-list',
    imports: [CommonModule],
    templateUrl: './tags-list.component.html',
    styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent {
  @Input() public tags: Tag[];
}
