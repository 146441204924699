import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { HubInstance } from '../../../common/models/hub/hub-instance';
import { SettingsEntity } from '../../../common/models/settings/settings-entity';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonService } from '../../../services/common.service';
import { InstanceService } from '../../../services/instance.service';
import { ProfileService } from '../../../services/profile.service';
import { SettingsService } from '../../../services/settings.service';
import { ToastService } from '../../../services/toast.service';
import { ArticleVisibility } from '../../../common/enums/article/article-visibility';
import { Routes } from '../../../common/constants/routes.constants';
import { SocialNetwork } from '../../../common/models/social-network/social-network';
import { SocialNetworkStatus } from '../../../common/enums/social-network/social-network-status';
import { SocialNetWorkService } from '../../../services/social-network.service';
import {
  faBookmark,
  faCalendarCheck,
  faCertificate,
  faClipboard,
  faFolderOpen,
  faImagePortrait,
  faNetworkWired,
  faNewspaper,
  faSquare,
  faSquarePollHorizontal,
  faTable,
  faTags,
  faTh,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { MenuItem } from '../../../common/models/side-bar/menu-item';
import { MenuData } from '../../../common/models/side-bar/menu-data';
import { ApiChainService, ApiSurveyService } from '@meplis/services';

@Component({
    selector: 'mec-side-bar',
    imports: [
        CommonModule,
        FontAwesomeModule,
        SkeletonDirective,
        TranslateModule,
    ],
    providers: [ApiProfileV3Service],
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  @Output() public itemClicked = new EventEmitter<void>();

  public instance: HubInstance;
  public isLogged: boolean;

  public hiddenAvatar: boolean;
  public hiddenLogo: boolean;
  public isLoadingAvatar: boolean;
  public isLoadingInstance: boolean;
  public isLoadingLogo: boolean;
  public isLoadingMenus: boolean;
  public isMobile: boolean;
  public socialNetworks: SocialNetwork[];
  public defaultPath: MenuItem;

  public sideBarData: MenuData = {
    backgroundImage: '../../../../../common/src/lib/assets/background-menu.jpg',
    menuItems: [],
  };

  public headerMenuItems: MenuItem[];

  public feedIcon = faTh;
  public userIcon = faUser;

  public hasPrograms: boolean;
  public hasActions: boolean;

  constructor(
    private _authService: AuthenticationService,
    private _instanceService: InstanceService,
    private _profileService: ProfileService,
    private _settingsService: SettingsService,
    private _socialNetworksService: SocialNetWorkService,
    private _toastService: ToastService,
    private _apiSurveyService: ApiSurveyService,
    private _apiChainService: ApiChainService,
    public _commonService: CommonService
  ) {
    this.isMobile = this._commonService.isMobile;
  }

  public async ngOnInit(): Promise<void> {
    this.isLoadingAvatar = true;
    this.isLoadingInstance = true;
    this.isLogged = this._authService.isLogged;

    if (!this.isLogged) {
      this.isLoadingAvatar = false;
      this.isLoadingInstance = false;
    } else {
      await this._getSocialNetworks();
      this.hasActions = await this._hasActions();
      this.hasPrograms = await this._hasPrograms();
    }

    await this.getInstance();

    if (this.isMobile) {
      this._getPagesMenus();
    }

    this._profileService.profileSubject.subscribe(async () => {
      if (this.isLogged) {
        await this._getProfile();
      }
    });
  }

  private async _getSocialNetworks(): Promise<void> {
    try {
      this.socialNetworks =
        await this._socialNetworksService.getSocialNetworks();
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private socialSubMenus(): MenuItem[] {
    const socialItems = this.socialNetworks.filter(
      (item) => item.status === SocialNetworkStatus.OPEN
    );

    return socialItems.map((socialItem: SocialNetwork) => ({
      name: socialItem.content.name,
      icon: faUsers,
      slug: Routes.socialNetworkGroups.fullPath.replace(
        ':networkId',
        socialItem.instanceId
      ),
      showItem: true,
    }));
  }

  private async getInstance(): Promise<void> {
    try {
      this.instance = await this._instanceService.getInstance(true);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingInstance = false;
  }

  private async _getPagesMenus(): Promise<void> {
    try {
      const instance: HubInstance = JSON.parse(JSON.stringify(this.instance));

      let menusItems = CommonHelper.orderByPropertyValue(
        instance?.menus,
        'order'
      );

      menusItems = menusItems.map((menu) => {
        if (menu.tag) {
          menu.targetSlug = `tags/${menu.targetSlug}`;
        }
        menu.items.forEach((item) => {
          if (item.tag) {
            item.targetSlug = `tags/${item.targetSlug}`;
          }
        });
        return menu;
      });

      const visibility = this.isLogged
        ? ArticleVisibility.LOGGED_IN
        : ArticleVisibility.NOT_LOGGED_IN;

      const menusFiltered = menusItems.filter(
        (item) =>
          item.visibility === visibility ||
          item.visibility === ArticleVisibility.BOTH
      );

      this.headerMenuItems = menusFiltered;
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingMenus = false;
  }

  private async _hasActions(): Promise<boolean> {
    try {
      const result = await this._apiSurveyService.getSurveysMe();
      return result?.length > 0;
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private async _hasPrograms(): Promise<boolean> {
    try {
      const permissionTenant = await this._settingsService.getPermissionByName(
        'CAREMONITOR_TENANT_INTEGRATION'
      );

      if (!permissionTenant?.value) {
        return false;
      }

      const result = await this._apiChainService.getProgramsCount(
        permissionTenant?.value
      );

      return result.count > 0;
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private _checkIfShowProgramOrActions(): MenuItem {
    if (this.hasPrograms) {
      return {
        name: Routes.actions.title,
        icon: faSquarePollHorizontal,
        slug: Routes.programs.fullPath,
        showItem: true,
      };
    }

    if (this.hasActions) {
      return {
        name: Routes.actions.title,
        icon: faSquarePollHorizontal,
        slug: Routes.actions.fullPath,
        showItem: true,
      };
    }

    return undefined;
  }

  private async _handleMenuItems(): Promise<void> {
    this.sideBarData.menuItems = [
      {
        name: null,
        icon: faSquare,
        showItem: true, //todo verirfy if has content
        items: [
          this._checkIfShowProgramOrActions(),
          {
            name: Routes.myCertificates.title,
            icon: faCertificate,
            slug: Routes.myCertificates.fullPath,
            showItem: true, //todo verify if has my certificates
          },
          {
            name: Routes.mySavedItems.title,
            icon: faNewspaper,
            slug: Routes.mySavedItems.fullPath,
            showItem: true, //todo verify if has my certificates
          },
        ],
      },
      {
        name: 'SOCIALNETWORK_SOCIAL',
        icon: faUsers,
        showItem: this.socialNetworks.length > 0,
        items: [
          {
            name: Routes.participantsProfile.title,
            icon: faImagePortrait,
            slug: 'participants/profile/me',
            showItem: true,
          },
          // {
          //   title: Routes.participantsCommunity.title,
          //   icon: faUsers,
          //   slug: Routes.participantsCommunity.fullPath,
          //   showItem: true,
          // },
          // {
          //   title: Routes.participantsManagement.title,
          //   icon: faUsersCog,
          //   slug: Routes.participantsManagement.fullPath,
          //   showItem:
          //     (await this._getPermission('HUB_PARTICIPANT')) !== null &&
          //     (await this._getPermission('COURSE_PARTICIPANT')) !== null,
          // },
        ],
      },
      {
        name: Routes.socialNetworkGroups.title,
        icon: faNetworkWired, // faChartNetwork,
        showItem: this.socialNetworks.length > 0,
        items: this.socialSubMenus(),
      },
      {
        name: 'CONTENT',
        icon: faBookmark,
        showItem: true, //todo verirfy if has content
        items: [
          {
            name: 'ALL_COURSES',
            icon: faBookmark,
            slug: Routes.courses.fullPath,
            showItem: true, //todo verify if has courses
          },
          {
            name: 'LIBRARY',
            icon: faFolderOpen,
            slug: Routes.library.fullPath,
            showItem:
              !(await this._settingsService._getPermissionValue(
                'DISABLE_LIBRARY'
              )) &&
              (await this._settingsService._getPermissionValue(
                'HAS_ITEMS_ON_LIBRARY'
              )),
          },
          {
            name: 'NEWS',
            icon: faNewspaper,
            slug: 'news',
            showItem:
              !(await this._settingsService._getPermissionValue(
                'DISABLE_NEWS'
              )) &&
              (await this._settingsService._getPermissionValue('HAS_NEWS')),
          },
          {
            name: 'EVENTS',
            icon: faCalendarCheck,
            slug: 'events',
            showItem:
              !(await this._settingsService._getPermissionValue(
                'DISABLE_EVENTS'
              )) &&
              (await this._settingsService._getPermissionValue('HAS_EVENTS')),
          },
          {
            name: 'TAGS',
            icon: faTags,
            slug: 'tags',
            showItem: await this._settingsService._getPermissionValue(
              'HAS_TAGS'
            ),
          },
        ],
      },
      //TODO CUSTOM HUBLINKS
      {
        name: 'EVALUATOR',
        icon: faSquare, // faBooks,
        showItem: this.instance?.role?.evaluator,
        items: [
          {
            name: 'EVALUATIONS',
            icon: faClipboard,
            slug: 'evaluations',
            showItem: true,
          },
          {
            name: 'REPORTS',
            icon: faTable,
            slug: 'reports',
            showItem: false, //todo implement page
          },
        ],
      },
      // {
      //   title: 'SETTINGS',
      //   icon: faCogs,
      //   showItem: this.isLogged,
      //   subMenus: [
      //     {
      //       title: 'PROFILE',
      //       icon: faUserCircle,
      //       slug: 'profile',
      //       showItem: this.isLogged,
      //     },
      //     {
      //       title: 'LOG_OUT',
      //       icon: faSignOut,
      //       slug: 'logout',
      //       showItem: this.isLogged,
      //     },
      //   ],
      // },
    ];
  }

  private async _getPermission(
    permissionName: string
  ): Promise<SettingsEntity> {
    return await this._settingsService.getPermissionByName(permissionName);
  }

  private async _getProfile(): Promise<void> {
    try {
      const profile = await this._profileService.getProfile();

      this.sideBarData = {
        ...this.sideBarData,
        name: profile.fullName,
        imageAvatarUrl: CommonHelper.getAvatarImage(profile.id),
        email: profile.email,
      };

      this._handleMenuItems();
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingAvatar = false;
  }

  public imageAvatarError(): void {
    this.hiddenAvatar = true;
  }

  public imageAvatarLoaded(): void {
    this.isLoadingAvatar = false;
    this.hiddenAvatar = false;
  }

  public goToPage(path: string): void {
    if (path === 'logout') {
      const logoutResult = this._authService.logOut(Routes.home.fullPath);
      if (logoutResult) {
        this._toastService.showSuccess('AUT.FEEDBACK.OPEN.LOGOUT.200.SUCCESS');
      }
      return;
    }

    const pathArray = path.split('/');
    this._commonService.navigate(pathArray);

    this.itemClicked.emit();
  }
}
