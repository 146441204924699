import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faCircle,
  faCircleCheck,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as startCircle } from '@fortawesome/free-regular-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramStepData } from '../../../common/models/care-monitor/program-step-data';
import { Program } from '../../../common/models/care-monitor/program';

@Component({
    selector: 'mec-program-steps',
    imports: [CommonModule, FontAwesomeModule, TranslateModule],
    templateUrl: './program-steps.component.html',
    styleUrls: ['./program-steps.component.scss']
})
export class ProgramStepsComponent implements OnChanges {
  @Input() public countSteps: number;
  @Input() public stepDescription: string;
  @Input() public currentStepIndex: number;

  public steps: Array<void> = [];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['countSteps']?.currentValue) {
      this.steps = Array(this.countSteps);
    }
  }

  public checkIcon = faCircleCheck;
  public listIcon = faList;
  public startIcon = startCircle;
  public endIcon = faCircle;
}
