<div class="program-policy card">
  <h4 [innerHTML]="programNode?.description"></h4>
  <div class="program-policy__options">
    <mat-radio-group [(ngModel)]="value" class="program-policy__radio-group">
      <mat-radio-button
        *ngFor="let item of values"
        [value]="item.value"
        (click)="$event.stopPropagation()"
        (change)="selectItem(item.value)"
        ><span (click)="$event.stopPropagation()">
          {{ item.name | translate }}
        </span></mat-radio-button
      >
    </mat-radio-group>

    <div *ngIf="value == '1'" class="program-policy__signature-pad">
      <signature-pad
        #signature
        [options]="signaturePadOptions"
        (drawEnd)="drawComplete($event)"
      ></signature-pad>

      <button (click)="clearSignature()" class="btn btn--sm btn--ico-only">
        <fa-icon [icon]="clearIcon"></fa-icon>
      </button>
    </div>
  </div>
</div>
