import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SearchResult,
  SearchResultList,
} from '../../../../common/models/common/search/search-result';
import { InfiniteScrollDirective } from '../../../../directives/infinite-scroll.directive';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonDirective } from '../../../../directives/skeleton.directive';
import { ToastService } from '../../../../services/toast.service';
import { ApiSearchService } from '../../../../services/api/api-search.service';
import { PaginationHelper } from '../../../../common/helpers/pagination.helper';
import { SearchCardComponent } from '../search-card/search-card.component';

@Component({
    selector: 'mec-search-cards-list',
    imports: [
        CommonModule,
        InfiniteScrollDirective,
        TranslateModule,
        SkeletonDirective,
        SearchCardComponent,
    ],
    templateUrl: './search-cards-list.component.html',
    styleUrls: ['./search-cards-list.component.scss']
})
export class SearchCardsListComponent implements OnChanges {
  @Input() public title = 'SEARCH';
  @Input() public tags: string;
  @Input() public searchFilter: string;

  public isLoading: boolean;
  public searchResultList: SearchResultList = new SearchResultList();

  constructor(
    private _apiSearchService: ApiSearchService,
    private _toastService: ToastService
  ) {}

  public async ngOnChanges(): Promise<void> {
    if (this.searchFilter || this.title || this.tags) {
      await this.search(true);
    }
  }

  public async search(reset?: boolean): Promise<void> {
    this.isLoading = true;

    if (reset) {
      this.searchResultList = new SearchResultList();
    }

    console.log(this.searchResultList);

    try {
      const itemsToAdd = await this._apiSearchService.getSearchResults(
        this.searchFilter,
        this.searchResultList?.pagination,
        null,
        this.tags
      );
      this.searchResultList =
        PaginationHelper.newHandlePagination<SearchResult>(
          this.searchResultList,
          'searchResult',
          itemsToAdd
        );
      console.log(this.searchResultList);
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.isLoading = false;
  }
}
