import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Model, PageModel } from 'survey-core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CampusSurveyComponent } from '../../../campus-survey/campus-survey.component';
import { ProgressBarComponent } from '../../../common/progress-bar/progress-bar.component';
import { HttpProgressEvent } from '@angular/common/http';
import { StepExamCommonInfoComponent } from '../step-exam-common-info/step-exam-common-info.component';
import {
  LibraryService,
  ToastService,
  MepStorage,
  ApiCoursesV2Service,
  ApiCoursesV1Service,
  CommonService,
} from '../../../../services';
import {
  StepExam,
  StepExamInstance,
  ExamAnswerSubmission,
  StepType,
  CommonHelper,
  StepSubmissionExam,
  FileEntity,
} from '../../../../common';
import { CourseEvaluationPreviewComponent } from '../../course-evaluation-preview/course-evaluation-preview.component';

@Component({
  selector: 'mec-step-exam',
  imports: [
    CommonModule,
    CampusSurveyComponent,
    MatProgressSpinnerModule,
    TranslateModule,
    ProgressBarComponent,
    StepExamCommonInfoComponent,
    CourseEvaluationPreviewComponent,
  ],
  templateUrl: './step-exam.component.html',
  styleUrls: ['./step-exam.component.scss'],
})
export class StepExamComponent implements OnInit, OnChanges {
  @Input() step: StepExam;
  @Input() courseId: number;

  public examInstance: StepExamInstance;
  public showExam: boolean;
  public showResults: boolean;
  public isLoading: boolean;

  public surveyModel: Model;
  public progressValue: number;
  public fileName: string;
  public showProgressBar: boolean;
  public token: string;

  constructor(
    private _apiCoursesV2Service: ApiCoursesV2Service,
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _changeDetectorRef: ChangeDetectorRef,
    private _commonService: CommonService,
    private _libraryService: LibraryService,
    private _mepStorage: MepStorage,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.token = await this._mepStorage.get('access_token');
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['step'] && changes['step']['firstChange']) {
      this._initialLoadExam();
    }
  }

  public goToExam(): void {
    this.showExam = true;
  }

  public async saveSubmissionExam(
    submissionId: number,
    entityValue: number | string
  ): Promise<void> {
    try {
      const submission = this.step.submissions.find(
        (submission) => submission.id === submissionId
      );

      const answerSubmission: ExamAnswerSubmission = {
        type: submission.type,
        question: submission,
      };

      if (submission.type === StepType.UPLOAD) {
        answerSubmission.file = entityValue as number;
      }

      if (submission.type === StepType.OPEN_TEXT) {
        answerSubmission.text = entityValue as string;
      }

      await this._apiCoursesV1Service.saveExamInstance<ExamAnswerSubmission>(
        this.courseId,
        this.step.id,
        submissionId,
        answerSubmission
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async completeExam(): Promise<void> {
    try {
      await this._apiCoursesV1Service.saveExamInstance(
        this.courseId,
        this.step.id
      );
      this.showExam = false;
      this._changeDetectorRef.detectChanges();
      await this._initialLoadExam(true);
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public openResults(): void {
    this.showResults = true;
  }

  //private methods

  private async _initialLoadExam(updateStep?: boolean): Promise<void> {
    if (updateStep) {
      await this.getStep();
    }
    await this.getExamInstance();
    this._setAnsweredQuestions();
    this._handleSurveyModel();
    this._changeDetectorRef.detectChanges();
  }

  private async getStep(): Promise<void> {
    this.isLoading = true;
    try {
      this.step = await this._apiCoursesV1Service.getCourseStepById(
        this.courseId,
        this.step.id
      );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  private async getExamInstance(): Promise<void> {
    this.isLoading = true;
    try {
      const instancies = this.step.instancies;
      const instance = this.step.instancies[this.step.instancies?.length - 1];

      //this was added because the user admin, don't have instance created, but need acess the course
      if (!instance?.id) {
        this.isLoading = false;
        return;
      }

      this.examInstance =
        await this._apiCoursesV1Service.getExamInstance<StepExamInstance>(
          instance?.course?.id,
          this.step.id,
          instance.id
        );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  private _handleSurveyModel(): void {
    const hasOrderOnQuestions = this.step.submissions.some(
      (submission) => submission.order && submission.order > 0
    );
    if (hasOrderOnQuestions) {
      this.step.submissions = CommonHelper.orderByPropertyValue(
        this.step.submissions,
        'order'
      );
    }

    this.step.submissions = CommonHelper.orderIfExistsProperty(
      this.step.submissions,
      'defaultValue'
    );
    const pages: any = [];

    this.step.submissions.forEach((submission) => {
      let page;

      if (submission.type === StepType.UPLOAD) {
        page = this.buildUploadPage(submission);
      } else if (submission.type === StepType.OPEN_TEXT) {
        page = this._buildOpenTextPage(submission);
      }

      pages.push(page);
    });

    const submissionsAnswered = pages.filter(
      (page: any) => page.elements[0].defaultValue
    );

    this.surveyModel = new Model({
      showPreviewBeforeComplete: 'showAllQuestions',
      pages: pages as unknown as PageModel[],
      mode: 'edit',
    });

    this._handleUploadEvents();

    if (submissionsAnswered.length) {
      this.surveyModel.currentPageNo = submissionsAnswered.length - 1;
    }
  }

  private _handleUploadEvents(): void {
    this.surveyModel.onUploadFiles.add((result, options: any) => {
      const formData = new FormData();
      options.files.forEach((file: any) => {
        formData.append(file.name, file);
      });

      this._libraryService.uploadFiles(
        options,
        (libraryFile: FileEntity, libraryFileConfirmResult: FileEntity[]) => {
          if (this._commonService.isPlatformBrowser) {
            setTimeout(() => {
              options.callback(
                'success',
                options.files.map((file: any, index: number) => {
                  return {
                    file: {
                      name: libraryFileConfirmResult[index].id,
                      type: file.type,
                    },
                    content: CommonHelper.getUrlFile(
                      libraryFileConfirmResult[index].fileHash,
                      null,
                      this.token
                    ),
                  };
                })
              );
            }, 1000);

            setTimeout(() => {
              this.showProgressBar = false;
              this.progressValue = 0;
              this._changeDetectorRef.detectChanges();
            }, 1200);
          } else {
            // mleon(): handling Server side, if necessary
          }
        },
        (progress: HttpProgressEvent) => {
          this._onProgressChange(
            options.files[0].name,
            progress.loaded,
            progress.total
          );
        }
      );

      return formData;
    });
  }

  private _onProgressChange(
    fileName: string,
    progressValue: number,
    totalValue: number
  ): void {
    if (!this.showProgressBar) {
      this.showProgressBar = true;
      this.fileName = fileName;
    }

    this.progressValue = Math.floor((progressValue / totalValue) * 100);

    this._changeDetectorRef.detectChanges();
  }

  private _buildOpenTextPage(submission: StepSubmissionExam): any {
    const textPage = {
      elements: [
        {
          type: 'comment',
          title: CommonHelper.removeHtmlTagsFromString(
            submission.content.title
          ),
          name: 'Question' + submission.id, //id is setted here because name is used to show results
          isRequired: true,
          description: submission?.content?.description,
          defaultValue: submission.defaultValue,
          validators: [
            {
              type: 'text',
              minLength: submission.minLength,
              maxLength: submission.maxLength,
            },
          ],
          maxLength: submission.maxLength,
        },
      ],
    };

    return textPage;
  }

  private buildUploadPage(submission: StepSubmissionExam): any {
    let title = this._translateService.instant('FILES_UPLOAD_MESSAGE');
    const allowedExtensionsTitle = this._translateService.instant(
      'FILES_ALLOWED_EXTENSIONS'
    );
    const allowedExtensions = submission.allowedExtensions
      .split(';')
      .join(', ');
    title = title + ' ' + allowedExtensionsTitle + ': ' + allowedExtensions;

    const uploadPage = {
      elements: [
        {
          type: 'file',
          title: title,
          description: submission?.content?.description,
          name: 'Question' + submission.id, //id is setted here because name is used to show results
          isRequired: true,
          defaultValue: submission.defaultValue,
          acceptedTypes: this._configureAcceptedTypes(
            submission.allowedExtensions
          ),
          storeDataAsText: false,
          waitForUpload: true,
          allowMultiple: false,
          maxSize: 2000000000,
          hideNumber: true,
        },
      ],
    };

    return uploadPage;
  }

  private _configureAcceptedTypes(allowedExtensions: string): string {
    const allowedExtensionsEdited: string[] = [];

    const allowedExtensionsArray = allowedExtensions.split(';');

    // const imageTypes = ['gif', 'jpg', 'jpeg', 'jng', 'png'];
    // const videoTypes = ['mp4', 'avi', 'mov', 'flv', 'm4v', 'mkv', 'qt', 'm4p', 'mpeg', 'mpg', 'mpv', 'nsv', 'rm', 'rmvb', 'svi', 'vob', 'webm', 'wmv', 'yuv'];

    const mimeTypes: { [key: string]: string } = {
      opus: 'video/ogg',
      ogv: 'video/ogg',
      mp4: 'video/mp4',
      mov: 'video/quicktime',
      m4v: 'video/m4v',
      mkv: 'video/x-matroska',
      avi: 'video/avi',
      flv: 'video/flv',
      qt: 'video/qt',
      m4p: 'video/m4p',
      mpeg: 'video/mpeg',
      mpg: 'video/mpg',
      mpv: 'video/mpv',
      nsv: 'video/nsv',
      rm: 'video/rm',
      rmvb: 'video/rmvb',
      svi: 'video/svi',
      vob: 'video/vob',
      webm: 'video/webm',
      wmv: 'video/wmv',
      yuv: 'video/yuv',
      m4a: 'audio/mp4',
      mp3: 'audio/mpeg',
      aac: 'audio/aac',
      caf: 'audio/x-caf',
      flac: 'audio/flac',
      oga: 'audio/ogg',
      wav: 'audio/wav',
      m3u8: 'application/x-mpegURL',
      mpd: 'application/dash+xml',
      csv: 'text/csv',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      gif: 'image/gif',
      png: 'image/png',
      svg: 'image/svg+xml',
      webp: 'image/webp',
    };

    allowedExtensionsArray.forEach((extension) => {
      let extensionEdited = mimeTypes[extension.toLocaleLowerCase()];

      if (!extensionEdited) {
        if (extension.indexOf('application') > -1) {
          extensionEdited = extension;
        } else {
          extensionEdited = 'application/' + extension;
        }
      }

      allowedExtensionsEdited.push(extensionEdited);
    });

    return allowedExtensionsEdited.join(',');
  }

  private _setAnsweredQuestions(): void {
    this.step.submissions.forEach((submission) => {
      const submissionAnswered = this.examInstance?.submissions?.find(
        (q) => q.submission.id === submission.id
      );
      if (
        submissionAnswered?.submission &&
        submission.type === StepType.UPLOAD
      ) {
        submission.defaultValue = {
          name: submissionAnswered?.file.toString(),
          type: submissionAnswered.mimeType,
          content: CommonHelper.getUrlFile(
            submissionAnswered?.fileHash,
            '&width=700',
            this.token
          ),
        };
        return;
      }

      submission.defaultValue = submissionAnswered?.text;
    });
  }
}
