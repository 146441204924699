import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  CommonHelper,
  EvaluationStatus,
  StepExam,
  StepExamInstance,
  StepExamMultiChoinceInstance,
} from '../../../../common';
import { StepExamRulesComponent } from '../step-exam-rules/step-exam-rules.component';
import { StepExamScoreInfoComponent } from '../step-exam-score-info/step-exam-score-info.component';
import { StepExamWaitingInfoComponent } from '../step-exam-waiting-info/step-exam-waiting-info.component';

@Component({
    selector: 'mec-step-exam-common-info',
    imports: [
        CommonModule,
        StepExamScoreInfoComponent,
        StepExamWaitingInfoComponent,
        StepExamRulesComponent,
        TranslateModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './step-exam-common-info.component.html',
    styleUrls: ['./step-exam-common-info.component.scss']
})
export class StepExamCommonInfoComponent implements OnChanges {
  @Input() step: StepExam;
  @Input() courseId: number;
  @Input() isLoading: boolean;
  @Input() examInstances: StepExamInstance[] | StepExamMultiChoinceInstance[];
  @Output() clickOpenResults = new EventEmitter<void>();
  @Output() clickGoToExam = new EventEmitter<void>();

  public submissionsStatus = EvaluationStatus;
  public examButtonText: string;
  public lastExamInstance: StepExamInstance | StepExamMultiChoinceInstance;
  public lastEvaluation: StepExamInstance | StepExamMultiChoinceInstance;

  ngOnChanges(): void {
    if (this.step) {
      this._handleExamViewOptions();
    }
  }

  public openResults(): void {
    this.clickOpenResults.emit();
  }

  public goToExam(): void {
    this.clickGoToExam.emit();
  }

  private _handleExamViewOptions(): void {
    const instances = CommonHelper.orderByPropertyValue(
      this.step.instancies,
      'startedAt'
    );

    this.lastExamInstance = instances[this.examInstances.length - 1];
    this.lastEvaluation = this.step.instancies
      .slice()
      .reverse()
      .find((instance) => instance.status === EvaluationStatus.FINISHED);

    this.examButtonText =
      this.lastExamInstance.status === EvaluationStatus.READY
        ? 'START_EXAM'
        : 'CONTINUE_EXAM';
  }
}
