import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../../services/common.service';

@Component({
    selector: 'mec-social-participants-search',
    imports: [
        CommonModule,
        MatCheckboxModule,
        TranslateModule,
        FontAwesomeModule,
    ],
    templateUrl: './social-participants-search.component.html',
    styleUrls: ['./social-participants-search.component.scss']
})
export class SocialParticipantsSearchComponent {
  @Output() public searchValueChanged = new EventEmitter<string>();
  public searchIcon = faSearch;

  constructor(private _commonService: CommonService) {}

  public searchParticipants(event: any): void {
    const searchValue = event['target']['value'];

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.searchValueChanged.emit(searchValue);
      }, 400);
    } else {
      this.searchValueChanged.emit(searchValue);
    }
  }
}
