import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ApiSavedItemsService } from '../../../services/api/api-saved-items.service';
import { ArticleType } from '../../../common/enums/article/article-type';
import { FooterComponent } from '../../common/footer/footer.component';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { LibraryItemComponent } from '../../library/library-item/library-item.component';
import { NewsCardComponent } from '../../news/news-card/news-card.component';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SavedItemsList } from '../../../common/models/saved-items/saved-items-list';
import { SearchCardComponent } from '../../common/search/search-card/search-card.component';
import { SearchResult } from '../../../common/models/common/search/search-result';
import { SettingsService } from '../../../services/settings.service';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';
import { SavedItemsService } from '../../../services/saved-items.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'mec-saved-items-card-list',
    imports: [
        CommonModule,
        FooterComponent,
        InfiniteScrollDirective,
        LibraryItemComponent,
        NewsCardComponent,
        SearchCardComponent,
        SkeletonDirective,
        TranslateModule,
    ],
    templateUrl: './saved-items-card-list.component.html',
    styleUrls: ['./saved-items-card-list.component.scss']
})
export class SavedItemsCardListComponent implements OnInit, OnDestroy {
  @Input() public limit: number;
  @Input() public excludes: number;
  @Input() public maxPage: number;

  public disableSavedItems: boolean;
  public isLoading = true;
  public savedItemsList: SavedItemsList = new SavedItemsList();
  public savedItemsSubscribe: Subscription;

  public contentTypeEvents = ArticleType.EVENTS;
  public contentTypeNews = ArticleType.NEWS;
  public contentTypeCourse = ArticleType.COURSE;
  public contentTypeLibraryFile = ArticleType.LIBRARY_FILE;

  constructor(
    private _apiSavedItemsService: ApiSavedItemsService,
    private _savedItemsService: SavedItemsService,
    private _settingsService: SettingsService,
    private _toastService: ToastService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.limit) {
      this.savedItemsList.pagination.limit = this.limit;
    }
    this.savedItemsList.pagination.maxPage = this.maxPage;
    this.disableSavedItems = await this._settingsService._getPermissionValue(
      'DISABLE_SAVED_ITEMS'
    );

    if (this.disableSavedItems) {
      return;
    }

    this.savedItemsSubscribe =
      this._savedItemsService._savedItemObservable.subscribe(
        (currentSavedItem) => {
          if (!currentSavedItem.savedItem) {
            this.removeItem(currentSavedItem);
          }
        }
      );

    await this.getSavedItemsList();
  }

  public ngOnDestroy(): void {
    this.savedItemsSubscribe.unsubscribe();
  }

  private removeItem(item: SearchResult) {
    for (const key in this.savedItemsList) {
      console.log(item, key, typeof key);
      // if (this.savedItemsList[key].id) {}
    }
  }

  public async getSavedItemsList(reset?: boolean): Promise<void> {
    this.isLoading = true;
    this.savedItemsList.pagination.isLoadingMore = true;

    if (reset) {
      this.savedItemsList = new SavedItemsList();
    }

    try {
      const itemsToAdd = await this._apiSavedItemsService.getSavedItems(
        this.savedItemsList.pagination
      );
      this.savedItemsList = PaginationHelper.newHandlePagination<SearchResult>(
        this.savedItemsList,
        'savedItems',
        itemsToAdd
      );
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.savedItemsList.pagination.isLoadingMore = false;
    this.isLoading = false;
  }
}
