import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';

@Component({
    selector: 'mec-social-hashtag-link',
    imports: [CommonModule],
    templateUrl: './social-hashtag-link.component.html',
    styleUrls: ['./social-hashtag-link.component.scss']
})
export class SocialHashtagLinkComponent {
  @Input() data: any;

  constructor(private _commonService: CommonService) {}

  public async ngOnInit(): Promise<void> {
    if (this.data) {
    }
  }

  public goToPage(profileId: string): void {
    this._commonService.navigate([
      Routes.participantsProfile.fullPath,
      profileId,
    ]);
  }
}
