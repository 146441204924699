import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampusSurveyComponent } from '../../../campus-survey/campus-survey.component';
import { Model, PageModel } from 'survey-core';
import { ToastService } from '../../../../services/toast.service';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { CourseSurveyQuestion } from '../../../../common/models/course/course-survey-question';
import { StepType } from '../../../../common/enums/course/step-type';
import { CourseSurveySubmission } from '../../../../common/models/course/course-survey-submission';
import { CourseStepSurvey } from '../../../../common/models/course/course-step-survey';
import { ApiCoursesV1Service } from '../../../../services/api/api-courses-v1.service';

@Component({
    selector: 'mec-step-survey',
    imports: [CommonModule, CampusSurveyComponent],
    providers: [ApiCoursesV1Service],
    templateUrl: './step-survey.component.html',
    styleUrls: ['./step-survey.component.scss']
})
export class StepSurveyComponent implements OnChanges {
  @Input() step: CourseStepSurvey;
  @Input() courseId: number;

  public isLoading: boolean;
  public surveyModel: Model;

  constructor(
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastService: ToastService
  ) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['step'] && changes['step']['firstChange']) {
      this._initialLoadExam();
    }
  }

  public async completeSurvey(
    questions: {
      questionId: number;
      entityValue: string | number;
    }[]
  ): Promise<void> {
    try {
      const answerSubmissions: CourseSurveySubmission[] = [];

      questions.forEach((question) => {
        const questionStep = this.step.questions.find(
          (q) => q.id === question.questionId
        );
        const answerSubmission: CourseSurveySubmission =
          new CourseSurveySubmission();

        answerSubmission.id = question.questionId;

        if (questionStep.type === StepType.MULTIPLE_CHOICE) {
          answerSubmission.answerId = question.entityValue as number;
        } else {
          answerSubmission.answer = question.entityValue as string;
        }

        answerSubmissions.push(answerSubmission);
      });

      await this._apiCoursesV1Service.saveSurvey<CourseSurveySubmission[]>(
        this.courseId,
        this.step.id,
        answerSubmissions
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  //private methods

  private async _initialLoadExam(): Promise<void> {
    this._setAnsweredQuestions();
    this._handleSurveyModel();
    this._changeDetectorRef.detectChanges();
  }

  private _handleSurveyModel(): void {
    const hasOrderOnQuestions = this.step.questions.some(
      (question) => question.order && question.order > 0
    );
    if (hasOrderOnQuestions) {
      this.step.questions = CommonHelper.orderByPropertyValue(
        this.step.questions,
        'order'
      );
    } else {
      this.step.questions = CommonHelper.orderIfExistsProperty(
        this.step.questions,
        'answerId'
      );
    }
    const elements: any = [];

    this.step.questions.forEach((question) => {
      let element;
      if (question.type == StepType.MULTIPLE_CHOICE) {
        element = this._buildPageTypeMultipleChoice(question);
      } else {
        element = this._buildOpenTextPage(question);
      }
      elements.push(element);
    });
    const pages = [
      {
        elements,
      },
    ];

    this.surveyModel = new Model({
      pages: pages as unknown as PageModel[],
    });
    if (this.step?.answers?.length) {
      this.surveyModel.mode = 'display';
    }
  }

  private _buildOpenTextPage(question: CourseSurveyQuestion): any {
    const textPage = {
      type: 'comment',
      title: CommonHelper.removeHtmlTagsFromString(question.content.question),
      name: 'Question' + question.id, //id is setted here because name is used to show results
      isRequired: question.mandatory,
      defaultValue: question.defaultValue,
      validators: [
        {
          type: 'text',
          minLength: question.minLength,
          maxLength: question.maxLength,
        },
      ],
      maxLength: question.maxLength,
    };

    return textPage;
  }

  private _buildPageTypeMultipleChoice(question: CourseSurveyQuestion): any {
    const booleanAnswersLabels = question.answers.map((answer) => {
      return {
        ...answer,
        text: answer.content.answer,
        value: answer.id,
      };
    });

    const type = question.acceptMultipleAnswers ? 'checkbox' : 'radiogroup';

    const page = {
      type: type,
      name: 'Question' + question.id, //id is setted here because name is used to show results
      title: CommonHelper.removeHtmlTagsFromString(question.content.question),
      defaultValue: question.defaultValue,
      isRequired: question.mandatory,
      data: { '': booleanAnswersLabels[0].content.answer },
      choices: CommonHelper.orderByPropertyValue(booleanAnswersLabels, 'order'),
    };

    return page;
  }

  private _setAnsweredQuestions(): void {
    this.step.questions.forEach((question) => {
      if (!this.step.answers?.length) {
        return;
      }
      const answer = this.step.answers.find(
        (a) => question.id === a.questionId
      );
      if (question.type === StepType.MULTIPLE_CHOICE) {
        question.defaultValue = answer.answerId;
        return;
      }

      question.defaultValue = answer?.answer;
    });
  }
}
