import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SocialNetWorkThread,
  SocialNetWorkThreadList,
} from '../../../common/models/social-network/social-network-thread';
import { PaginationOptions } from '../../../common/models/common/pagination-options';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { ToastService } from '../../../services/toast.service';
import { SocialCommentItemComponent } from '../social-comment-item/social-comment-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ProfileService } from '../../../services/profile.service';

@Component({
    selector: 'mec-social-comment-list',
    imports: [
        CommonModule,
        SocialCommentItemComponent,
        TranslateModule,
        MatProgressSpinnerModule,
        SkeletonDirective,
    ],
    templateUrl: './social-comment-list.component.html',
    styleUrls: ['./social-comment-list.component.scss']
})
export class SocialCommentListComponent implements OnChanges {
  @Input() public socialThread: SocialNetWorkThread;

  public comments: SocialNetWorkThreadList = new SocialNetWorkThreadList();
  public isLoading: boolean;
  public fisr: boolean;

  constructor(
    private _apiSocialNetworksV1Service: ApiSocialNetworksV1Service,
    private _toastService: ToastService,
    private _apiSocialNetworkV1Service: ApiSocialNetworksV1Service
  ) {}

  public async ngOnChanges(): Promise<void> {
    if (this.socialThread?.commented) {
      await this.getCommments();
    }
  }

  public async deleteThread(socialThread: SocialNetWorkThread): Promise<void> {
    try {
      await this._apiSocialNetworkV1Service.deletePublication(
        socialThread.token,
        socialThread.instancesId
      );

      this.comments.threads = this.comments.threads.filter(
        (thread) => thread.token !== socialThread.token
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }
  public async getCommments(reset?: boolean): Promise<void> {
    this.isLoading = true;

    if (reset) {
      this.comments = new SocialNetWorkThreadList();
    }

    try {
      const commentsResult =
        await this._apiSocialNetworksV1Service.getCommentsFrom(
          this.socialThread.instancesId,
          this.socialThread.token,
          this.comments.pagination
        );

      this.comments = PaginationHelper.newHandlePagination<SocialNetWorkThread>(
        this.comments,
        'threads',
        commentsResult
      );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }
}
