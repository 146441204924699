<div
  *ngIf="
    (programNode?.message && programNode?.dependencies?.length) ||
    programNode?.messageValue
  "
  class="program-output card"
>
  <h4
    *ngIf="programNode?.labelText || programNode?.name"
    [innerHTML]="programNode?.labelText || programNode?.name"
  ></h4>
  <span
    *ngIf="programNode?.messageValue"
    [innerHTML]="programNode?.messageValue"
  ></span>
  <span
    *ngIf="!programNode?.messageValue"
    [innerHTML]="programNode?.message"
  ></span>
</div>
