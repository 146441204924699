import { ToastService } from './../../../../services/toast.service';
import { FileTypes } from './../../../../common/enums/file/file-types';
import { LibraryService } from './../../../../services/library.service';
import { FileEntity } from './../../../../common/models/files/file-entity';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrash, faVideo, faFile } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-file-upload-list',
    imports: [CommonModule, MatProgressSpinnerModule, FontAwesomeModule],
    providers: [LibraryService],
    templateUrl: './file-upload-list.component.html',
    styleUrls: ['./file-upload-list.component.scss']
})
export class FileUploadListComponent implements OnChanges {
  @Input() filesEvent: Event;
  @Output() public confirmFileChange = new EventEmitter<FileEntity>();
  @Output() public removeFileChange = new EventEmitter<number>();
  public files: FileEntity[] = [];
  public hasAnyImageLoading: boolean;

  public fileTypes = FileTypes;

  public removeIcon = faTrash;
  public videoIcon = faVideo;
  public documentIcon = faFile;

  constructor(
    private _toastService: ToastService,
    private _libraryService: LibraryService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.filesEvent) {
      this.loadFileFromDevice(this.filesEvent);
    }
  }

  public clearFiles(): void {
    this.filesEvent = undefined;
    this.files = [];
  }

  public removeFile(fileSelected: FileEntity): void {
    this.files = this.files.filter((file) => file.id !== fileSelected.id);
    this.removeFileChange.emit(fileSelected.id);
  }

  public async loadFileFromDevice($event: Event): Promise<void> {
    this.hasAnyImageLoading = this.files.some((file) => file.isLoading);

    this._libraryService.uploadFiles(
      $event,
      (libraryFile, libraryFileConfirmResult) =>
        this.confirmFile(libraryFile, libraryFileConfirmResult),
      null,
      this.files
    );
  }

  public verifyIfHasLoading(): void {
    this.hasAnyImageLoading = this.files.some((file) => file.isLoading);
  }

  public async confirmFile(
    libraryFile: FileEntity,
    libraryFileConfirmResult: FileEntity[]
  ): Promise<void> {
    if (libraryFileConfirmResult?.length) {
      libraryFile.id = libraryFileConfirmResult[0].id;
      libraryFile.isLoading = false;
      libraryFile.type = libraryFileConfirmResult[0].type;

      libraryFileConfirmResult[0].fileId = libraryFileConfirmResult[0].id;

      this.hasAnyImageLoading = this.files.some((file) => file.isLoading);

      this.confirmFileChange.emit(libraryFileConfirmResult[0]);
    }
  }
}
