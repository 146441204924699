import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastService } from '../../../services/toast.service';
import { EventWebinar } from '../../../common/models/event/event-webinar';
import { ApiWebinarsService } from '../../../services/api/api-webinars.service';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'mec-event-question',
    imports: [
        CommonModule,
        TranslateModule,
        FontAwesomeModule,
        FormsModule,
        MatProgressSpinnerModule,
    ],
    providers: [ApiWebinarsService],
    templateUrl: './event-question.component.html',
    styleUrls: ['./event-question.component.scss']
})
export class EventQuestionComponent implements OnInit {
  @Input() public webinar: EventWebinar;

  public question: string;
  public isSaving: boolean;
  public isLoading: boolean;

  constructor(
    private _toastService: ToastService,
    private _apiWebinarsService: ApiWebinarsService
  ) {}

  public ngOnInit(): void {}

  public saveQuestion(): void {
    if (!this.question) {
      return;
    }

    this.isSaving = true;

    try {
      this._apiWebinarsService.saveMessageRoom(
        this.webinar.token,
        this.webinar.chatRoom.roomId,
        this.question
      );

      this._toastService.showSuccess('WEBINARS.QUESTION.SUCCESS');
      this.question = undefined;
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isSaving = false;
  }
}
