import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconLookup, IconProp } from '@fortawesome/fontawesome-svg-core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectionOptions } from '../../common/enums/common/direction-options';
import { HookAction } from '../../common/enums/hook/hook-actions';
import { CommonHelper } from '../../common/helpers/common.helper';
import { Hook } from '../../common/models/hook/hook';
import { HubInstance } from '../../common/models/hub/hub-instance';
import { SkeletonDirective } from '../../directives/skeleton.directive';
import { ApiChainService } from '../../services/api/api-chain.service';
import { ApiEventsService } from '../../services/api/api-events.service';
import { ApiHooksService } from '../../services/api/api-hooks.service';
import { ApiNewsService } from '../../services/api/api-news.service';
import { CommonService } from '../../services/common.service';
import { ApiCoursesV2Service } from '../../services/api/api-courses-v2.service';
import { ApiCoursesV1Service } from '../../services/api/api-courses-v1.service';
import {
  faCircleInfo,
  faList,
  faFolderOpen,
  faGlobe,
  faUsers,
  faCalendarAlt,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import { InstanceService } from '../../services/instance.service';
import { SettingsService } from '../../services/settings.service';
import { ToastService } from '../../services/toast.service';

@Component({
    selector: 'mec-dashboard-default-cards',
    imports: [
        CommonModule,
        FontAwesomeModule,
        TranslateModule,
        SkeletonDirective,
    ],
    providers: [
        ApiHooksService,
        ApiNewsService,
        ApiEventsService,
        ApiCoursesV1Service,
        ApiCoursesV2Service,
        ApiChainService,
    ],
    templateUrl: './dashboard-default-cards.component.html',
    styleUrls: ['./dashboard-default-cards.component.scss']
})
export class DashboardDefaultCardsComponent implements OnInit {
  public hasCourses: boolean;
  public hasEvents: boolean;
  public hasHooks: boolean;
  public hasNews: boolean;
  public hooks: Hook[];
  public instance: HubInstance;
  public programsCount: number;
  public welcomeScholarMessage: string;

  public loading: boolean;
  public loadingInstance: boolean;

  public icons: { [key: string]: IconLookup } = {
    events: faCalendarAlt,
    feed: faGlobe,
    info: faCircleInfo,
    library: faFolderOpen,
    news: faNewspaper,
    participants: faUsers,
    programs: faList,
  };

  constructor(
    private _apiChainService: ApiChainService,
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _apiEventsService: ApiEventsService,
    private _apiHooksService: ApiHooksService,
    private _apiNewsService: ApiNewsService,
    private _instanceService: InstanceService,
    private _settingsService: SettingsService,
    private _toastService: ToastService,
    public _commonService: CommonService
  ) {}

  public async ngOnInit() {
    this.loading = true;
    this.loadingInstance = true;

    await this._getHooks();

    if (this.hooks && this.hooks.length) {
      this.hasHooks = true;
      this.loadingInstance = false;
    } else {
      await this._getProgramsCount();

      this._getInstance();
      this._getNews();
      this._getEvents();
      this._getCourses();
    }

    this.loading = false;
  }

  public goToPage(page: string, action?: HookAction): void {
    if (
      action === HookAction.EXTERNAL_URL ||
      action === HookAction.EXTERNAL_DOWNLOAD
    ) {
      // window.open(page, '_blank');
      this._commonService.windowOpen(page, '_blank');
      return;
    }

    this._commonService.navigateByUrl(
      `${this._commonService.getBasePath()}/${page}`
    );
  }

  private async _getHooks(): Promise<void> {
    try {
      this.hooks = await this._apiHooksService.getHooks();
      this._handleHooks();
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private async _getInstance(): Promise<void> {
    try {
      this.instance = await this._instanceService.getInstance();
    } catch (error) {
      this._toastService.showError(error);
    }
    this.loadingInstance = false;
  }

  private _handleHooks(): void {
    this.hooks.forEach((hook) => {
      hook.iconProp = this._commonService.getIcon(hook.icon);

      if (
        hook.action === HookAction.EXTERNAL_URL ||
        hook.action === HookAction.EXTERNAL_DOWNLOAD
      ) {
        hook.url = hook.parameters['url'];
        return;
      }

      switch (hook.parameters['stateName']) {
        case 'app.network.global':
          hook.url = `network/${hook.parameters['networkId']}`;
          break;
        default:
          hook.url = CommonHelper.getUrlByState(hook.parameters['stateName']);
      }
    });
  }

  private async _getCourses(): Promise<void> {
    try {
      const pagination = {
        direction: DirectionOptions.desc,
        limit: 3,
        page: 0,
      };
      const coursesResult = await this._apiCoursesV1Service.getCourses(
        pagination
      );
      this.hasCourses = coursesResult && coursesResult?.courses.length > 0;
    } catch (error: any) {
      this._toastService.showError(error);
    }
  }

  private async _getNews(): Promise<void> {
    try {
      const pagination = {
        direction: DirectionOptions.desc,
        limit: 10,
        page: 0,
      };

      const newsResult = await this._apiNewsService.getNews(pagination);
      this.hasNews = newsResult && newsResult.length > 0;
    } catch (error: any) {
      this._toastService.showError(error);
    }
  }

  private async _getEvents(): Promise<void> {
    try {
      const pagination = {
        direction: DirectionOptions.desc,
        limit: 5,
        page: 0,
      };

      const eventsResult = await this._apiEventsService.getEvents(pagination);
      this.hasEvents = eventsResult && eventsResult.length > 0;
    } catch (error: any) {
      this._toastService.showError(error);
    }
  }

  private async _getProgramsCount(): Promise<void> {
    try {
      const tenantPermission = await this._settingsService.getPermissionByName(
        'CAREMONITOR_TENANT_INTEGRATION'
      );

      if (tenantPermission) {
        const programsCountResult =
          await this._apiChainService.getProgramsCount(tenantPermission.value);
        this.programsCount = programsCountResult.count;
      }
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
