<form class="program-text card">
  <h4>{{ programNode?.labelText }}</h4>
  <div class="program-text__options">
    <input
      *ngIf="programNode.subType !== 'PHONE'"
      [type]="type"
      [formControl]="formControl"
      class="field__input"
    />
    <div *ngIf="programNode.subType === 'PHONE'" class="mobile">
      <div
        class="mobile__country"
        [ngClass]="{
          'mobile__country--country-mode': selectMobileCoutryMode
        }"
      >
        <div
          class="mobile__ico-container"
          (click)="setMobileCoutryMode(true)"
          *ngIf="currentMobileCountry && !selectMobileCoutryMode"
        >
          <span
            class="fi fi-{{ getLowerIsoCode(currentMobileCountry.isoCode) }}"
          ></span>
          <fa-icon [icon]="arrowDownIcon"></fa-icon>
        </div>
        <mec-autocomplete
          *ngIf="selectMobileCoutryMode"
          [formGroup]="formGroup"
          [items]="listCoutriesAutocomplete"
          [controlName]="'mobileCountry'"
          (optionSelectedChange)="setCountryFlagCode($event)"
        ></mec-autocomplete>
      </div>
      <input class="field__input" type="number" [formControl]="formControl" />
    </div>
  </div>
</form>
