import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StepType } from '../../../common/enums/course/step-type';
import { Course } from '../../../common/models/course/courses';
import { Step } from '../../../common/models/course/step';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { CoursesService } from '../../../services/courses.service';
import { ToastService } from '../../../services/toast.service';
import { CommonService } from '../../../services/common.service';
import { ApiCoursesV1Service } from '../../../services/api/api-courses-v1.service';
import {
  faChevronRight,
  faCircleCheck,
  faListCheck,
  faBookOpen,
  faCertificate,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-course-steps',
    imports: [CommonModule, SkeletonDirective, FontAwesomeModule],
    providers: [ApiCoursesV1Service],
    templateUrl: './course-steps.component.html',
    styleUrls: ['./course-steps.component.scss']
})
export class CourseStepsComponent implements OnChanges {
  @Input() course: Course;
  @Input() isSideBar: boolean;
  @Input() stepsCourse: Step[];

  @Output() closeSteps = new EventEmitter<void>(); //used if course is used inside a modal or side menu
  @Output() nextStep = new EventEmitter<number>();

  public loadingSteps: boolean;

  public submissionTypes = StepType;
  public arrowIcon = faChevronRight;
  public checkIcon = faCircleCheck;
  public examIcon = faListCheck;
  public contentIcon = faBookOpen;
  public certificateIcon = faCertificate;

  constructor(
    private _coursesService: CoursesService,
    private _toastService: ToastService,
    public _apiCoursesService: ApiCoursesV1Service,
    public _apiCoursesV1Service: ApiCoursesV1Service,
    public _commonService: CommonService,
    public _translateService: TranslateService,
    public translateService: TranslateService
  ) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['course']?.currentValue && changes['course']?.firstChange) {
      if (!this.stepsCourse) {
        await this.getStepsFromCourse();
      }
    }
  }

  public async getStepsFromCourse(): Promise<void> {
    this.loadingSteps = true;

    try {
      const stepsResult = await this._apiCoursesV1Service.getCourseSteps(
        this.course.id
      );
      this.stepsCourse = this._coursesService.orderChildrenSteps(stepsResult);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.loadingSteps = false;
  }

  public goToStep(step: Step): void {
    if (!step || !step.unlocked) {
      return;
    }

    const extras: NavigationExtras = {
      queryParams: {
        courseId: this.course.id,
      },
      replaceUrl: true,
    };

    this._commonService.navigate(
      ['course', this.course.slug, 'step', step.id as unknown as string],
      extras
    );

    if (this.isSideBar) {
      this.nextStep.emit(step.id);
      return;
    }

    this.closeSteps.emit();
  }
}
