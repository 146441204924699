import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyModule } from 'survey-angular-ui';
import { Model } from 'survey-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'mec-campus-survey',
    imports: [CommonModule, SurveyModule],
    templateUrl: './campus-survey.component.html',
    styleUrls: ['./campus-survey.component.scss']
})
export class CampusSurveyComponent implements OnChanges {
  @Input() public surveyModel: Model = new Model();
  @Input() public hideSurvey: boolean;
  @Input() public hideCloseButton: boolean;
  @Output() public surveyModelChange = new EventEmitter<{
    questionId: number;
    entityValue: number | string;
  }>();
  @Output() public completeSurvey = new EventEmitter<
    {
      questionId: number;
      entityValue: number | string;
    }[]
  >();
  @Output() public closeSurvey = new EventEmitter();

  public surveyHasAlreadyBuilded: boolean;
  public closeButtonAdded: boolean;

  constructor(private _translateService: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.surveyHasAlreadyBuilded) {
      return;
    }

    if (this.surveyModel) {
      this.surveyModel.onCurrentPageChanging.add((survey) => {
        this._answerSurvey(survey);
      });

      this.surveyModel.onShowingPreview.add((survey) => {
        this._answerSurvey(survey);
      });

      this.surveyModel.onComplete.add((survey) => {
        const valuesHash = survey.getAllValues();
        const values = Object.keys(valuesHash).map((key: string) => {
          const questionId = parseInt(key.replace('Question', ''));

          const valueWithId: {
            questionId: number;
            entityValue: number | string;
          } = {
            questionId,
            entityValue: valuesHash[key],
          };

          return valueWithId;
        });
        this.completeSurvey.emit(values);
      });

      if (!this.hideCloseButton && !this.closeButtonAdded) {
        this.surveyModel.addNavigationItem({
          id: 'survey-close',
          title: this._translateService.instant('CLOSE'),
          action: () => {
            this.closeSurvey.emit();
          },
          innerCss: 'sd-btn nav-input',
        });

        this.closeButtonAdded = true;
      }

      this.surveyHasAlreadyBuilded = true;

      //todo set language by sruvey model
      //this.surveyModel.locale = 'pt-br';
    }
  }

  private _answerSurvey(survey: Model): void {
    if (survey.mode === 'display') {
      return;
    }

    const element = survey?.currentPage?.elements[0];
    const isQuestionAnswer = element?.name?.indexOf('Question') > -1;

    if (!isQuestionAnswer) {
      return;
    }

    const answerType = element.getType();

    const questionId = parseInt(
      survey.currentPage.elements[0].name.replace('Question', '')
    );
    let entityValue: number;

    const data = survey.data[survey.currentPage.elements[0].name];

    if (!data) {
      return;
    }

    if (answerType === 'file') {
      entityValue = Array.isArray(data) ? data[0].name : data.name;
    } else if (answerType === 'comment') {
      entityValue = data;
    } else {
      entityValue = parseInt(survey.data[survey.currentPage.elements[0].name]);
    }

    this.surveyModelChange.emit({ questionId, entityValue });
  }
}
