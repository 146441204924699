import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialPublicationBodyComponent } from '../social-publication-body/social-publication-body.component';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { CommonHelper } from '../../../common/helpers/common.helper';

@Component({
    selector: 'mec-social-repost-body',
    imports: [CommonModule, SocialPublicationBodyComponent],
    templateUrl: './social-repost-body.component.html',
    styleUrls: ['./social-repost-body.component.scss']
})
export class SocialRepostBodyComponent implements OnChanges {
  @Input() socialThreadToRepost: SocialNetWorkThread;

  public avatarUrl: string;

  constructor() {}

  public ngOnChanges(): void {
    if (this.socialThreadToRepost) {
      this.avatarUrl = CommonHelper.getAvatarImage(
        this.socialThreadToRepost.profile.id
      );
    }
  }
}
