import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { SummaryNews } from '../../../common/models/news/summary-news';
import { TagsListComponent } from '../../common/tags-list/tags-list.component';

@Component({
    selector: 'mec-news-card',
    imports: [
        CommonModule,
        SkeletonDirective,
        TranslateModule,
        TagsListComponent,
    ],
    templateUrl: './news-card.component.html',
    styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnChanges {
  @Input() news: SummaryNews;

  public imageUrl: string;
  public hiddenImage: boolean;
  public isLoadingImage = true;

  constructor(private _commonService: CommonService) {}

  public ngOnChanges(): void {
    if (this.news?.defaultImageHash) {
      this.imageUrl = CommonHelper.getUrlFile(this.news.defaultImageHash);
    }
  }

  public goToNewsDetail(): void {
    this._commonService.navigate(['news', this.news.slug]);
  }
}
