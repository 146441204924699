import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  CookieBarComponent,
  FooterComponent,
  HeaderComponent,
  SideBarComponent,
} from '@meplis/components';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import {
  ApiProfileV3Service,
  AuthenticationService,
  CommonService,
  GTMService,
  Hub,
  InstanceService,
  ProfileService,
} from '@meplis/services';
import { HubInstance } from '@meplis/common';
import { config, dom } from '@fortawesome/fontawesome-svg-core';
import { Subject } from 'rxjs';
import { HubConfigurationService } from './hubs/hub-configuration.service';
import { BASE_PATH } from './app.routes';

@Component({
    imports: [
        CommonModule,
        RouterModule,
        MatSnackBarModule,
        HeaderComponent,
        FooterComponent,
        SideBarComponent,
        CookieBarComponent,
    ],
    providers: [ProfileService, ApiProfileV3Service],
    selector: 'mec-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('headerTemplate', { read: ViewContainerRef })
  headerTemplate: ViewContainerRef;

  @ViewChild('footerTemplate', { read: ViewContainerRef })
  footerTemplate: ViewContainerRef;

  @ViewChild('sideBarTemplate', { read: ViewContainerRef })
  sideBarTemplate: ViewContainerRef;

  @ViewChild('cookieBarTemplate', { read: ViewContainerRef })
  cookieBarTemplate: ViewContainerRef;

  public disableMarginPageContent: boolean;
  public hideHeaderAndFooter: boolean;
  public hideSideBar: boolean;
  public instance: HubInstance;
  public onIsMobileChange = new Subject<void>();
  private _isOnline = true;

  public rootRouteConfigured: boolean;

  public blockedHeaderPages = [
    'authentication',
    'login',
    'recovery-password',
    'sign-up',
  ];

  public blockedSideBarPages = [
    'authentication',
    'cookie-policy',
    'course/',
    'disclaimer',
    'home',
    'legal-notice',
    'login',
    'privacy-policy',
    'sign-up',
  ];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    public _commonService: CommonService,
    public _iconLibrary: FaIconLibrary,
    private _authService: AuthenticationService,
    private _gtmService: GTMService,
    private _hubConfigurationService: HubConfigurationService,
    private _hubService: Hub,
    private _intanceService: InstanceService,
    private _location: Location,
    private _profileService: ProfileService,
    private _router: Router
  ) {
    this._initFontAwesome();
    this._initGTM();

    this._router.events.subscribe(async (item) => {
      const route = this._location.path();

      this._handleRoute(route);

      if (route !== '' && !this.rootRouteConfigured) {
        this._handleRootRoute();
        this.rootRouteConfigured = true;
      }

      this._loadSideBar();
    });

    this._authService.onLoggedInOut.subscribe(() => {
      this._handleUserAnalytics();
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick($event: Event): void {
    if (
      $event.target instanceof HTMLAnchorElement ||
      $event.target instanceof HTMLButtonElement
    ) {
      const value = $event.target.innerText.substring(0, 64);
      const label =
        $event.target instanceof HTMLAnchorElement ? $event.target.href : value;

      this._gtmService.tagLink({
        event_label: label,
        value: value,
      });
    }
  }

  @HostListener('window:offline', ['$event'])
  onWindowOffline() {
    this._isOnline = false;
    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'connection',
      event_category: 'app-network-information',
      event_label: 'offline',
    });
  }

  @HostListener('window:online', ['$event'])
  onWindowOnline() {
    this._isOnline = true;
    // mleon(): todo normalize with GOOGLE_ANALYTICS constants
    this._gtmService.tagEvent({
      event_action: 'connection',
      event_category: 'app-network-information',
      event_label: 'online',
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this._commonService.verifyIfIsMobile();
  }

  public async ngOnInit(): Promise<void> {
    this.instance = await this._intanceService?.getInstance();
    this._handleDefaultComponents();
  }

  public get isOnline(): boolean {
    return this._isOnline;
  }

  private async _handleRootRoute(): Promise<void> {
    const hubConfiguration = this._hubService.getHubConfiguration();
    this._hubService.setFixedHub(hubConfiguration.slug);
    this._hubService.setBasePath(BASE_PATH);
    await this._commonService.handleBaseUrl(hubConfiguration?.slug);
  }

  private _loadSideBar(): void {
    if (this.sideBarTemplate && !this.hideSideBar) {
      this._hubConfigurationService.loadComponent(
        this.sideBarTemplate,
        SideBarComponent,
        'sideBar'
      );
    }
  }

  private _handleDefaultComponents(): void {
    const hubBlockedSideBarPages = this._hubService.getHubConfigurationByProp(
      'blockedSideBarPages'
    );
    if (hubBlockedSideBarPages) {
      this.blockedSideBarPages = hubBlockedSideBarPages;
      const route = this._location.path();
      this._handleRoute(route);
    }

    if (this.footerTemplate) {
      this._hubConfigurationService.loadComponent(
        this.footerTemplate,
        FooterComponent,
        'footer'
      );
    }

    if (this.headerTemplate) {
      this._hubConfigurationService.loadComponent(
        this.headerTemplate,
        HeaderComponent,
        'header'
      );
    }

    if (this.cookieBarTemplate) {
      this._hubConfigurationService.loadComponent(
        this.cookieBarTemplate,
        CookieBarComponent,
        'cookieBar'
      );
    }

    this._loadSideBar();
  }

  // https://github.com/FortAwesome/angular-fontawesome/issues/407#issuecomment-1934995751
  private _initFontAwesome() {
    // This code was heavily inspired by https://www.skovy.dev/blog/server-side-rendering-font-awesome
    // FontAwesome icons loaded the way we do start HUGE initially until the CSS is injected. This injects the CSS immediately.
    const head = this._document.getElementsByTagName('head')[0];
    const styleNode = this._document.createElement('style');

    config.autoAddCss = false; // Disable FA's CSS injection
    styleNode.innerHTML = dom.css(); // grab FA's CSS

    head.appendChild(styleNode);

    this._iconLibrary.addIconPacks(fas, far, fab);
  }

  private _initGTM() {
    this._router.events.subscribe(async (item) => {
      if (item instanceof NavigationEnd) {
        this._handleRouteAnalytics();
      }
    });
  }

  private _handleRouteAnalytics(): void {
    this._gtmService.tagView();
  }

  private _handleRoute(route: string): void {
    this.hideHeaderAndFooter = this._filterBlockedHeaderPages(route);

    if (!this._authService.isLogged) {
      this.hideSideBar = true;

      return;
    }

    this.hideSideBar = this._filterBlockedSideBarPages(route);
  }

  private _filterBlockedSideBarPages(slug: string): boolean {
    return this.blockedSideBarPages.some((link) => slug.indexOf(link) > -1);
  }

  private _filterBlockedHeaderPages(slug: string): boolean {
    return this.blockedHeaderPages.some((link) => slug.indexOf(link) > -1);
  }

  private _handleUserAnalytics() {
    if (this._authService.isLogged) {
      this._profileService.getId().then((userId) => {
        if (userId) {
          this._gtmService.tag('config', {
            userId: userId,
          });
        }
      });
    }
  }
}
