<div class="program-matrix-radio card">
  <h4>{{ programNode?.labelText }}</h4>
  <div class="program-matrix-radio__groups">
    <div
      class="program-matrix-radio__group"
      *ngFor="let chain of programNode.chains; let i = index"
      [ngClass]="{
        'program-matrix-radio__group--no-border':
          i === programNode.chains.length - 1
      }"
    >
      <h5>{{ chain.labelText }}</h5>
      <mat-radio-group class="program-matrix-radio__radio-group">
        <mat-radio-button
          *ngFor="let item of programNode?.values"
          [value]="item.value"
          (click)="$event.stopPropagation()"
          (change)="selectItem(chain.variable, item.value)"
          ><span (click)="$event.stopPropagation()">
            {{ item.name }}
          </span></mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
</div>
