import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { SummaryEvent } from '../../../common/models/event/summary-event';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { NavigationExtras } from '@angular/router';

@Component({
    selector: 'mec-event-banner',
    imports: [CommonModule, TranslateModule, SkeletonDirective],
    templateUrl: './event-banner.component.html',
    styleUrls: ['./event-banner.component.scss']
})
export class EventBannerComponent implements OnInit {
  @Input() public event: SummaryEvent;
  @Input() public hideWatch: boolean;
  @Input() public isLogged: boolean;

  public isLoadingImage: boolean;
  public hiddenImage: boolean;
  public defaultImageUrl: string;

  public currentPath: string;

  constructor(
    private _commonService: CommonService,
    private _location: Location
  ) {
    this.currentPath = this._location.path();
  }

  public ngOnInit(): void {
    this.defaultImageUrl = CommonHelper.getUrlFile(
      this.event?.defaultImageHash,
      '&width=1200'
    );
  }

  public login(): void {
    const extras: NavigationExtras = {
      queryParams: {
        routeUrlToRedirect: this.currentPath,
      },
    };
    this._commonService.navigate(['login'], extras);
  }

  public imageError(): void {
    this.hiddenImage = true;
  }

  public imageLoaded(): void {
    this.isLoadingImage = false;
    this.hiddenImage = false;
  }
}
