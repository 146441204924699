import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonService } from '../../../services/common.service';
import { SocialPublicationBodyComponent } from '../social-publication-body/social-publication-body.component';
import { SocialPublicationReactionComponent } from '../social-publication-reaction/social-publication-reaction.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SocialPublicationReactionsInfoComponent } from '../social-publication-reactions-info/social-publication-reactions-info.component';
import { SocialCommentListComponent } from '../social-comment-list/social-comment-list.component';
import { AlertService } from '../../../services/alert.service';
import { MatMenuModule } from '@angular/material/menu';
import { SocialPublicationCreateBodyComponent } from '../social-publication-create-body/social-publication-create-body.component';
import { ToastService } from '../../../services/toast.service';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModalService } from '../../../services/modal.service';
import { SocialPublicationCreateModalComponent } from '../social-publication-create-modal/social-publication-create-modal.component';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { SocialRepostBodyComponent } from '../social-repost-body/social-repost-body.component';
import { SocialContentCardComponent } from '../social-content-card/social-content-card.component';
import {
  faGlobe,
  faEllipsis,
  faComment,
  faRepeat,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-social-publication-item',
    imports: [
        CommonModule,
        FontAwesomeModule,
        SocialPublicationBodyComponent,
        SocialPublicationReactionComponent,
        SocialPublicationReactionsInfoComponent,
        SocialCommentListComponent,
        TranslateModule,
        MatMenuModule,
        SocialPublicationCreateBodyComponent,
        MatProgressSpinnerModule,
        SocialRepostBodyComponent,
        SocialContentCardComponent,
    ],
    templateUrl: './social-publication-item.component.html',
    styleUrls: ['./social-publication-item.component.scss']
})
export class SocialPublicationItemComponent implements OnChanges {
  @Input() socialThread: SocialNetWorkThread;
  @Output() socialThreadDeleteChange = new EventEmitter<void>();

  @ViewChild(SocialCommentListComponent)
  public socialCommentListComponent: SocialCommentListComponent;

  @ViewChild(SocialPublicationCreateBodyComponent)
  public socialPublicationCreateBodyComponent: SocialPublicationCreateBodyComponent;

  public newComment: SocialNetWorkThread = new SocialNetWorkThread();

  public globeIcon = faGlobe;
  public editIcon = faEllipsis;
  public commentIcon = faComment;
  public repostIcon = faRepeat;
  public profileId: string;

  public avatarUrl: string;

  public isCommentMode: boolean;
  public isSaving: boolean;

  constructor(
    public _commonService: CommonService,
    private _translateService: TranslateService,
    private _alertService: AlertService,
    private _toastService: ToastService,
    private _modalService: ModalService,
    private _apiSocialNetworkV1Service: ApiSocialNetworksV1Service,
    private _socialNetWorksService: SocialNetWorkService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.socialThread) {
      this.avatarUrl = CommonHelper.getAvatarImage(
        this.socialThread.profile.id
      );
    }
  }

  public goToProfile(): void {
    this._commonService.navigate([
      'participants',
      'profile',
      this.socialThread.profile.id as unknown as string,
    ]);
  }

  public setCommentMode(): void {
    this.isCommentMode = true;
  }

  public publicationBodyChange(text: string): void {
    this.socialThread.body = text;
  }

  public async deleteThread(): Promise<void> {
    const titleAlert = this._translateService.instant('CONFIRM_DELETE');
    const alertResult = await this._alertService.showConfirmation(
      titleAlert,
      '',
      'CORE.POPUP_CONFIRM.CONFIRM',
      'CANCEL'
    );

    if (alertResult) {
      this.socialThreadDeleteChange.emit();
    }
  }

  public async repostClick(): Promise<void> {
    const modalResult = await this._modalService.openModal<
      SocialPublicationCreateModalComponent,
      {
        profileId: string;
        socialNetWorkInstaceId?: string;
        initalInputType?: 'link' | 'image-video' | 'file';
        socialThreadToRepost: SocialNetWorkThread;
      }
    >(
      SocialPublicationCreateModalComponent,
      {
        profileId: this.profileId,
        socialNetWorkInstaceId: this.socialThread?.instancesId,
        initalInputType: null,
        socialThreadToRepost: this.socialThread,
      },
      {},
      800
    );

    modalResult.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._socialNetWorksService.socialPublicationsReloadSubject.next(true);
      }
    });
  }

  public async saveComment(): Promise<void> {
    this.isSaving = true;

    try {
      let resultNewComment = await this._apiSocialNetworkV1Service.saveComment(
        this.newComment,
        this.socialThread?.instancesId,
        this.socialThread?.token
      );

      this.socialThread.commented = true;
      this.socialPublicationCreateBodyComponent.clearInput();

      this.newComment = new SocialNetWorkThread();

      this.socialCommentListComponent.getCommments(true);
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isSaving = false;
  }
}
