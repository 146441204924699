import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step } from '../../../common/models/course/step';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StepExamInstance } from '../../../common/models/evaluation/exam/step-exam-instance';
import { ToastService } from '../../../services/toast.service';
import { AlertService } from '../../../services/alert.service';
import { EvaluationStatus } from '../../../common/enums/evaluation/evaluation-status';
import { EvaluationService } from '../../../services/evaluation.service';
import { ApiCoursesV2Service } from '../../../services/api/api-courses-v2.service';
import { ApiCoursesV1Service } from '../../../services/api/api-courses-v1.service';
import { StepExamMultiChoinceInstance } from '../../../common/models/evaluation/exam-multi-choice/step-exam-multi-choice-instance';
import { OwnContentType } from '../../../common/enums/common/own-content-type';
import { StepExamRule } from '../../../common/models/evaluation/rules/step-exam-rule';
import { InstanceService } from '@meplis/services';
import { ScoreSystemType } from '../../../common/models/course/score-system-type';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
    selector: 'mec-course-evaluation-score',
    imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule],
    providers: [ApiCoursesV1Service, ApiCoursesV2Service],
    templateUrl: './course-evaluation-score.component.html',
    styleUrls: ['./course-evaluation-score.component.scss']
})
export class CourseEvaluationScoreComponent implements OnChanges, OnDestroy {
  @Input() step: Step;
  @Input() examInstance: StepExamInstance | StepExamMultiChoinceInstance;
  @Input() hideFeedback: boolean;
  @Output() examChange = new EventEmitter<{ feedbackText: string }>();

  public formArray: FormArray;
  public feedback: string;
  public evaluations: EvaluationScore[] = [];
  public rules: StepExamRule[] = [];
  public stepScoreValue: number = 0.1;
  public subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _tranlateService: TranslateService,
    private _alertService: AlertService,
    private _toastService: ToastService,
    private _instanceService: InstanceService,
    private _evaluationService: EvaluationService
  ) {
    this._evaluationService.updateEvaluation();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.examInstance) {
      this.evaluations = [];
      this.formArray = this._formBuilder.array([]);

      this.step.evaluations.forEach(async (evaluation) => {
        let score: number | string = this.examInstance.evaluations.find(
          (ev) => ev.evaluation.id === evaluation.id
        )?.score;

        if (typeof score === 'number') {
          score = score.toFixed(1);
        }

        const maxValue = await this._getMaxScore();

        const formGroup: FormGroup = this._formBuilder.group({
          score: [
            score,
            [Validators.min(0), Validators.max(maxValue), Validators.required],
          ],
        });

        const evaluationObject: EvaluationScore = {
          id: evaluation.id,
          title: evaluation.content.title,
          description: evaluation.content.description,
          status: this.examInstance.status,
          ownContentType: OwnContentType.evaluation,
          form: formGroup,
        };

        const subscription = formGroup.valueChanges
          .pipe(debounceTime(1000))
          .pipe(distinctUntilChanged())
          .subscribe(() => {
            this.saveScore(evaluationObject);
          });

        this.subscriptions.push(subscription);

        this.evaluations.push(evaluationObject);
        this.formArray.push(formGroup);
      });
    }
  }

  public clearInput(formGroup: FormGroup, name: string) {
    formGroup.controls[name].setValue(null);
  }

  private async _getMaxScore(): Promise<number> {
    const instance = await this._instanceService.getInstance();
    const type: ScoreSystemType = instance.scoreSystemType;

    let maxScore = null;

    if (type === 'ZERO_TO_TEN') {
      maxScore = 10;
    } else if (type === 'OSAT' || type === 'ZERO_TO_FIVE') {
      if (type === 'OSAT') {
        this.stepScoreValue = 1;
      }
      maxScore = 5;
    }

    return maxScore;
  }

  public async saveScore(evaluation: EvaluationScore): Promise<void> {
    if (evaluation.form.invalid) {
      return;
    }

    const evaluationScore = {
      evaluation: {
        id: evaluation.id,
        ownContentType: OwnContentType.evaluation,
      },
      score: evaluation.form.controls['score'].value,
    };

    try {
      await this._apiCoursesV1Service.saveEvaluationScore(
        this.examInstance.step.course.id,
        this.examInstance.step.id,
        this.examInstance.id,
        evaluationScore
      );
      this.examChange.emit({
        feedbackText: this.feedback,
      });
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async finishEvaluation(): Promise<void> {
    if (this.formArray.invalid) {
      return;
    }

    const alertResult = await this._alertService.showConfirmation(
      this._tranlateService.instant('EVALUATION'),
      this._tranlateService.instant('EVALUATION_CONFIRM_FINISH'),
      'Ok',
      'CANCEL'
    );

    if (!alertResult) {
      return;
    }

    try {
      await this._apiCoursesV1Service.finishEvaluation(
        this.examInstance.step.course.id,
        this.examInstance.step.id,
        this.examInstance.id,
        {
          feedback: this.feedback,
        }
      );

      this._toastService.showSuccess();
      this._evaluationService.updateEvaluation();
      this.examChange.emit();
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}

export class EvaluationScore {
  id: number;
  title: string;
  description: string;
  status: EvaluationStatus;
  ownContentType: OwnContentType = OwnContentType.evaluation;
  form: FormGroup;
}
