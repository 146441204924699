import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetworkReactions } from '../../../common/enums/social-network/social-network-reactions';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { SocialNetworkReaction } from '../../../common/models/social-network/social-network-reaction';
import { ModalService } from '../../../services/modal.service';
import { SocialReactionListModalComponent } from '../social-reaction-list-modal/social-reaction-list-modal.component';
import { SocialNetWorkReactionsInfo } from '../../../common/models/social-network/social-network-reaction-info';

@Component({
    selector: 'mec-social-publication-reactions-info',
    imports: [CommonModule, FontAwesomeModule],
    providers: [ApiSocialNetworksV1Service],
    templateUrl: './social-publication-reactions-info.component.html',
    styleUrls: ['./social-publication-reactions-info.component.scss']
})
export class SocialPublicationReactionsInfoComponent implements OnChanges {
  @Input() public socialThread: SocialNetWorkThread;
  public givenReactions: SocialNetworkReaction[] = [];
  public reactionsInfo: SocialNetWorkReactionsInfo =
    new SocialNetWorkReactionsInfo();
  public reactionsTypes = SocialNetworkReactions;

  constructor(private _modalService: ModalService) {}

  async ngOnChanges(): Promise<void> {
    if (this.socialThread?.countReactions) {
      this.givenReactions = this.socialThread?.countReactions;
    }
  }

  public async openReactionModal(): Promise<void> {
    await this._modalService.openModal<
      SocialReactionListModalComponent,
      SocialNetWorkThread
    >(SocialReactionListModalComponent, this.socialThread);
  }
}
