import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { ToastService } from '../../../services/toast.service';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { SocialNetworkReactions } from '../../../common/enums/social-network/social-network-reactions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faThumbsUp,
  faHeart,
  faLightbulb,
  faHandHoldingHeart,
  faFaceLaughBeam,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-social-publication-reaction',
    imports: [CommonModule, FontAwesomeModule, TranslateModule],
    providers: [ApiSocialNetworksV1Service],
    templateUrl: './social-publication-reaction.component.html',
    styleUrls: ['./social-publication-reaction.component.scss']
})
export class SocialPublicationReactionComponent implements OnChanges {
  @Input() socialThread: SocialNetWorkThread;

  public reactions: {
    [name: string]: { name: string; icon: IconProp; label: string };
  };
  public showReactions: boolean;
  public reactionsTypes = SocialNetworkReactions;
  public mainIcon: { name: string; icon: IconProp; label: string };
  public givenReaction: boolean;

  constructor(
    private _apiSocialV1Service: ApiSocialNetworksV1Service,
    private _toastService: ToastService
  ) {
    this.reactions = {
      [this.reactionsTypes.LIKE]: {
        name: this.reactionsTypes.LIKE,
        icon: faThumbsUp,
        label: 'Like',
      },
      [this.reactionsTypes.LOVE]: {
        name: this.reactionsTypes.LOVE,
        icon: faHeart,
        label: 'Love',
      },
      [this.reactionsTypes.GENIUS]: {
        name: this.reactionsTypes.GENIUS,
        icon: faLightbulb,
        label: 'Genius',
      },
      [this.reactionsTypes.SUPPORT]: {
        name: this.reactionsTypes.SUPPORT,
        icon: faHandHoldingHeart,
        label: 'Support',
      },
      [this.reactionsTypes.FUNNY]: {
        name: this.reactionsTypes.FUNNY,
        icon: faFaceLaughBeam,
        label: 'Funny',
      },
    };
    this.mainIcon = this.reactions[this.reactionsTypes.LIKE];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.socialThread?.reaction) {
      this.mainIcon = this.reactions[this.socialThread?.reaction];
      this.givenReaction = true;
    }
  }

  public async putReaction(reaction: string): Promise<void> {
    if (
      this.givenReaction &&
      this.reactions[reaction].name === this.mainIcon.name
    ) {
      this.removeReaction(
        this.reactions[reaction].name as SocialNetworkReactions
      );
      return;
    }

    try {
      this.mainIcon = this.reactions[reaction];
      this.givenReaction = true;

      await this._apiSocialV1Service.putReactionPublication(
        this.socialThread.instancesId,
        this.socialThread.token,
        reaction as SocialNetworkReactions
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public async removeReaction(reaction: SocialNetworkReactions): Promise<void> {
    try {
      this.mainIcon = this.reactions[this.reactionsTypes.LIKE];
      this.givenReaction = false;

      await this._apiSocialV1Service.removeReactionPublication(
        this.socialThread.instancesId,
        this.socialThread.token,
        reaction
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
