import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Model, PageModel } from 'survey-core';
import { TranslateModule } from '@ngx-translate/core';
import { CampusSurveyComponent } from '../../../campus-survey/campus-survey.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  StepExamMultiChoinceInstance,
  MultiChoiceAnswerSubmission,
  CommonHelper,
  MultipleChoiceQuestionAnswerType,
  MultiChoiceQuestion,
} from '../../../../common';
import {
  ApiCoursesV2Service,
  ToastService,
  MepStorage,
  ApiCoursesV1Service,
} from '../../../../services';
import { StepExamCommonInfoComponent } from '../step-exam-common-info/step-exam-common-info.component';
import { StepExamMultiChoice } from '../../../../common/models/evaluation/exam-multi-choice/step-exam-multi-choice';

@Component({
    selector: 'mec-step-multichoice-exam',
    imports: [
        CommonModule,
        TranslateModule,
        CampusSurveyComponent,
        MatProgressSpinnerModule,
        StepExamCommonInfoComponent,
    ],
    templateUrl: './step-multichoice-exam.component.html',
    styleUrls: ['./step-multichoice-exam.component.scss']
})
export class StepMultichoiceExamComponent implements OnInit, OnChanges {
  @Input() step: StepExamMultiChoice;
  @Input() courseId: number;

  public examMultiChoiceInstance: StepExamMultiChoinceInstance;
  public showExam: boolean;
  public isLoading: boolean;

  public surveyModel: Model;
  public token: string;

  constructor(
    private _apiCoursesV2Service: ApiCoursesV2Service,
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _mepStorage: MepStorage,
    private _changeDetectorRef: ChangeDetectorRef,
    private _toastService: ToastService
  ) {}

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['step'] && changes['step']['firstChange']) {
      this._initialLoadExam();
    }
  }

  async ngOnInit(): Promise<void> {
    this.token = await this._mepStorage.get('access_token');
  }

  public goToExam(): void {
    this.showExam = true;
  }

  public openResults(): void {
    this.surveyModel.mode = 'display';
    this.surveyModel.questionsOnPageMode = 'singlePage';
    this.showExam = true;
  }

  public async saveMultichoiceExam(
    questionId: number,
    entityValue: string | number
  ): Promise<void> {
    try {
      const question = this.step.questions.find(
        (question: { id: number }) => question.id === questionId
      );

      const answerSubmission: MultiChoiceAnswerSubmission = {
        type: this.step.type,
        question: question,
        isAnswered: true,
        answer: [entityValue as number],
        submittedAt: new Date(),
      };

      await this._apiCoursesV1Service.saveExamInstance<MultiChoiceAnswerSubmission>(
        this.courseId,
        this.step.id,
        questionId,
        answerSubmission
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private async getStep(): Promise<void> {
    this.isLoading = true;
    try {
      this.step = await this._apiCoursesV1Service.getCourseStepById(
        this.courseId,
        this.step.id
      );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  public async completeMultichoiceExam(): Promise<void> {
    try {
      await this._apiCoursesV1Service.saveExamInstance(
        this.courseId,
        this.step.id
      );
      this.showExam = false;
      this._changeDetectorRef.detectChanges();
      await this._initialLoadExam(true);
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  //private methods

  private async _initialLoadExam(updateStep?: boolean): Promise<void> {
    if (updateStep) {
      await this.getStep();
    }

    await this.getExamInstance();
    this._setAnsweredQuestions();
    this._handleSurveyModel();
    this._changeDetectorRef.detectChanges();
  }

  private async getExamInstance(): Promise<void> {
    this.isLoading = true;
    try {
      //get first element because was reverted list on the backend and returned on top of array the next instance
      const instanceId = this.step.instancies[0].id;
      this.examMultiChoiceInstance =
        await this._apiCoursesV1Service.getExamInstance<StepExamMultiChoinceInstance>(
          this.courseId,
          this.step.id,
          instanceId
        );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  private _handleSurveyModel(): void {
    const hasOrderOnQuestions = this.step.questions.some(
      (question: { order: number }) => question.order && question.order > 0
    );
    if (hasOrderOnQuestions) {
      this.step.questions = CommonHelper.orderByPropertyValue(
        this.step.questions,
        'order'
      );
    } else {
      this.step.questions = CommonHelper.orderIfExistsProperty(
        this.step.questions,
        'answerId'
      );
    }
    const pages: any = [];

    this.step.questions.forEach((question: MultiChoiceQuestion) => {
      let page;
      if (question.answerType === MultipleChoiceQuestionAnswerType.TEXT) {
        page = this._buildPageTypeText(question);
      } else if (
        question.answerType === MultipleChoiceQuestionAnswerType.MEDIA
      ) {
        page = this._buildPageTypeMedia(question);
      }

      pages.push(page);
    });

    const questionsAnswered = pages.filter(
      (page: any) => page.elements[0].defaultValue
    );
    this.surveyModel = new Model({
      showPreviewBeforeComplete: 'showAllQuestions',
      pages: pages as unknown as PageModel[],
    });
    if (questionsAnswered.length) {
      this.surveyModel.currentPageNo = questionsAnswered.length - 1;
    }
  }

  private _buildPageTypeMedia(question: MultiChoiceQuestion): any {
    const booleanAnswersLabels = question.booleanAnswers.map((answer) => {
      return {
        ...answer,
        text: answer.content.label,
        value: answer.id,
        imageLink: CommonHelper.getUrlFile(
          answer.content.fileHash,
          null,
          this.token
        ),
      };
    });

    const type = 'imagepicker';

    const page = {
      elements: [
        {
          type: type,
          name: 'Question' + question.id, //id is setted here because name is used to show results
          defaultValue: question.answerId,
          title: CommonHelper.removeHtmlTagsFromString(
            question.content.content
          ),
          isRequired: true,
          choices: CommonHelper.orderByPropertyValue(
            booleanAnswersLabels,
            'order'
          ),
        },
      ],
    };

    //todo verify mediType is type video or image and chenage contentMode

    //todo if will have both video and image options on same questionuse the example code bellow
    // {
    //   "pages": [
    //     {
    //       "name": "page1",
    //       "elements": [
    //         {
    //           "type": "panel",
    //           "name": "animals",
    //           "elements": [
    //             {
    //               "type": "checkbox",
    //               "name": "question1",
    //               "titleLocation": "hidden",
    //               "choices": [
    //                 "other"
    //               ]
    //             },
    //             {
    //               "type": "image",
    //               "name": "question2",
    //               "imageLink": "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    //               "contentMode": "video",
    //               "imageFit": "cover",
    //               "imageHeight": "300px",
    //               "imageWidth": "100%"
    //             },
    //             {
    //               "type": "checkbox",
    //               "name": "question3",
    //               "titleLocation": "hidden",
    //               "choices": [
    //                 {
    //                   "value": "lion",
    //                   "text": "Lion"
    //                 }
    //               ]
    //             },
    //             {
    //               "type": "image",
    //               "name": "question4",
    //               "imageLink": "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg",
    //               "contentMode": "image",
    //               "imageFit": "cover",
    //               "imageHeight": "auto",
    //               "imageWidth": "100%"
    //             }
    //           ],
    //           "title": "Which animals would you like to see in real life?"
    //         }
    //       ]
    //     }
    //   ],
    //     "widthMode": "static"
    // }

    return page;
  }

  private _buildPageTypeText(question: MultiChoiceQuestion): any {
    const booleanAnswersLabels = question.booleanAnswers.map((answer) => {
      return {
        ...answer,
        text: answer.content.label,
        value: answer.id,
      };
    });

    const type = question.acceptMultipleAnswers ? 'checkbox' : 'radiogroup';

    const page = {
      elements: [
        {
          type: type,
          name: 'Question' + question.id, //id is setted here because name is used to show results
          defaultValue: question.answerId,
          title: CommonHelper.removeHtmlTagsFromString(
            question.content.content
          ),
          isRequired: true,
          data: { '': booleanAnswersLabels[0].text },
          choices: CommonHelper.orderByPropertyValue(
            booleanAnswersLabels,
            'order'
          ),
        },
      ],
    };

    return page;
  }

  private _setAnsweredQuestions(): void {
    this.step.questions.forEach((question: any) => {
      const questionAnswered = this.examMultiChoiceInstance.questions.find(
        (q) => q.question.id === question.id
      );
      if (questionAnswered?.answers?.length > 0) {
        question.answerId = questionAnswered.answers[0].answer.id;
      }
    });
  }
}
