import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ToastService } from '../../../services/toast.service';
import { ApiChainService } from '../../../services/api/api-chain.service';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { ProgramService } from '../../../services/program.service';

@Component({
  selector: 'mec-program-output',
  imports: [CommonModule],
  templateUrl: './program-output.component.html',
  styleUrls: ['./program-output.component.scss'],
})
export class ProgramOutputComponent {
  @Input() public programNode: ProgramNode;
  @Input() public logs: ProgramLog[];
  @Input() public programToken: string;

  constructor(
    private _toastService: ToastService,
    private _apiChainService: ApiChainService,
    private _programService: ProgramService
  ) {
    this._programService.onStepDependecyChange.subscribe(
      async (dependencyId: string) => {
        console.log(this.programNode);
        if (
          `${this.programNode.project}::${dependencyId}` === this.programNode.id
        ) {
          const logsWithoutOwn = this.logs.filter(
            (log) => log.to && log.to !== this.programNode.id
          );
          await this._executeSentece(logsWithoutOwn);
        }
      }
    );
  }

  private async _executeSentece(logs: ProgramLog[]): Promise<void> {
    try {
      const result = await this._apiChainService.executeSentence(
        this.programToken,
        this.programNode,
        logs
      );

      if (result) {
        this.programNode.messageValue = result;
      }
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
