<div class="program-arithmetic card">
  <h4
    *ngIf="programNode?.labelText || programNode?.name"
    [innerHTML]="programNode?.labelText || programNode?.name"
  ></h4>
  <span
    *ngIf="programNode?.sentenceValue"
    [innerHTML]="programNode?.sentenceValue"
  ></span>
</div>
