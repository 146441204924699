import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicHtmlModule } from '../../common/dynamic-html/dynamic-html.module';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { ModalService } from '../../../services/modal.service';
import { FileEntity } from '../../../common/models/files/file-entity';
import { NetworkConstants } from '../../../common/constants/network.constants';
import { TranslateModule } from '@ngx-translate/core';
import { FileViewerModalComponent } from '../../common/file/file-viewer/file-viewer-modal.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { RetryUrlFileDirective } from '../../../directives/retry-url-file.directive';
import { CommonService } from '../../../services/common.service';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { ToastService } from '../../../services/toast.service';
import { MepFile } from '../../../services/abstract/mep-file.abstract';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FILE_MIME_TYPE } from '../../../common/constants/file.constants';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-social-publication-body',
    imports: [
        CommonModule,
        DynamicHtmlModule,
        TranslateModule,
        RetryUrlFileDirective,
        FontAwesomeModule,
    ],
    templateUrl: './social-publication-body.component.html',
    styleUrls: ['./social-publication-body.component.scss']
})
export class SocialPublicationBodyComponent implements OnChanges, OnInit {
  @Input() socialThread: SocialNetWorkThread;

  @ViewChild('bodyText', { read: ElementRef, static: false })
  bodyText: ElementRef;

  public body: string;
  public files: FileEntity[];
  public showSeeMore: boolean;
  public showSeeLess: boolean;
  public isDownloading: boolean;

  public accessToken: string;

  public downloadIcon = faDownload;

  constructor(
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _modalService: ModalService,
    private _toastService: ToastService,
    private _mepFile: MepFile
  ) {}

  public async ngOnInit(): Promise<void> {
    this.accessToken = this._authService.getToken();
  }

  public ngOnChanges(): void {
    if (this.socialThread?.body) {
      this.body = this.formatBody(this.socialThread?.body);
      this.handleTextSize();
    }

    if (this.socialThread?.attachments) {
      this.files = this.socialThread?.attachments.map(
        (attachment) => attachment.file
      );
    }
  }

  public handleTextSize(): void {
    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        const bodyTextHeight = this.bodyText.nativeElement.scrollHeight;
        if (bodyTextHeight > 60) {
          this.showSeeMore = true;
        }
      }, 200);
    } else {
      this.showSeeMore = true;
    }
  }

  public getApplicationType(type: string): string {
    const applicationType = FILE_MIME_TYPE[type];
    return applicationType;
  }

  public seeMore(): void {
    this.showSeeMore = false;
    this.showSeeLess = true;
  }

  public seeLess(): void {
    this.showSeeLess = false;
    this.showSeeMore = true;
  }

  public retakeFile(event: ElementRef, file: FileEntity): void {
    event.nativeElement.src = file.url;
  }

  public formatBody(body: string): string {
    NetworkConstants.patterns.forEach((pattern) => {
      const test = body.match(pattern.formula);
      body = body.replace(pattern.formula, pattern.output.directive);
    });

    return body;
  }

  public async downloadFile(
    url: string,
    fileNameAndExtension: string
  ): Promise<void> {
    this.isDownloading = true;
    try {
      await this._mepFile.downloadFileFromUrl(url, fileNameAndExtension);
    } catch (error) {
      this._toastService.showError(error);
    }

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.isDownloading = false;
      }, 500);
    } else {
      this.isDownloading = false;
    }
  }

  public openFileViewer(currentFileId: number): void {
    const modalRef = this._modalService.openModal(
      FileViewerModalComponent,
      { files: this.files, currentFileId: currentFileId },
      null,
      1140
    ) as any;

    modalRef.afterClosed().subscribe((modalResul: any) => {
      if (modalResul) {
        //todo
      }
    });
  }
}
