<div class="program-media card">
  <div class="program-media__header">
    <h4>{{ programNode?.description }}</h4>
    <div class="program-media__header-options">
      <button
        *ngIf="
          programNode?.log || (currentFile && this.showProgressBar === false)
        "
        (click)="clearFile()"
        class="program-media__remove-button btn btn--icon-only"
      >
        <span>{{ 'REMOVE' | translate }}</span>
        <fa-icon [icon]="removeIcon"></fa-icon>
      </button>
    </div>
  </div>

  <mec-progress-bar
    *ngIf="showProgressBar"
    [fileName]="fileName"
    [progressValue]="progressValue"
  ></mec-progress-bar>

  <div
    *ngIf="programNode?.log || (currentFile && this.showProgressBar === false)"
    class="program-media__preview"
  >
    <img
      *ngIf="programNode?.validation?.type === 'image'"
      [src]="srcImage || currentFile.imageBlob"
    />
    <div
      *ngIf="programNode?.validation?.type === 'video'"
      class="program-media__file-container"
    >
      <fa-icon class="program-media__preview-icon" [icon]="videoIcon"></fa-icon>
    </div>

    <div
      *ngIf="programNode?.validation?.type === 'file'"
      class="program-media__file-container"
    >
      <fa-icon
        *ngIf="programNode?.validation?.type === 'file'"
        class="program-media__preview-icon"
        [icon]="fileIcon"
      ></fa-icon>
    </div>
  </div>

  <div
    *ngIf="!currentFile && !srcImage"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave()"
    class="program-media__container"
    [ngClass]="{ 'program-media__container--on-drag': onDrag === true }"
  >
    <fa-icon class="program-media__cloud-icon" [icon]="cloudIcon"></fa-icon>
    <h4 *ngIf="programNode?.validation?.type === 'image'">
      {{ 'COMPONENT.CHAIN_MEDIA.DROP_IMAGE_HERE' | translate }}
    </h4>
    <h4 *ngIf="programNode?.validation?.type === 'video'">
      {{ 'COMPONENT.CHAIN_MEDIA.DROP_VIDEO_HERE' | translate }}
    </h4>
    <h4 *ngIf="programNode?.validation?.type === 'file'">
      {{ 'COMPONENT.CHAIN_MEDIA.DROP_FILE_HERE' | translate }}
    </h4>
    <h4 *ngIf="programNode?.validation?.type === 'audio'">
      {{ 'COMPONENT.CHAIN_MEDIA.DROP_FILE_HERE' | translate }}
    </h4>
    <span>{{ 'PROGRAMS.RUN.OR' | translate }}</span>
    <button (click)="inputFile.click()" class="btn btn--sm btn-ico-rigth">
      <fa-icon [icon]="uploadIcon"></fa-icon>
      <span
        >{{ 'SETTINGS.PROFILE.MAIN.TIMEZONE.PLACEHOLDER' | translate }}
        {{ 'FILE' | translate }}</span
      >
    </button>

    <input
      #inputFile
      [accept]="acceptTypes"
      id="inputFile"
      class="program-media__file-input"
      type="file"
      (change)="loadFile($event)"
      hidden
    />
  </div>
</div>
