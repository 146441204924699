import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { SummaryEvent } from '../../../common/models/event/summary-event';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { TagsListComponent } from '../../common/tags-list/tags-list.component';

@Component({
    selector: 'mec-event-card',
    imports: [
        CommonModule,
        SkeletonDirective,
        TranslateModule,
        TagsListComponent,
    ],
    templateUrl: './event-card.component.html',
    styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent {
  public imageUrl: string;
  public hiddenImage: boolean;
  public isLoadingImage = true;

  @Input() public event: SummaryEvent;

  constructor(private _commonService: CommonService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.event?.defaultImageHash) {
      this.imageUrl = CommonHelper.getUrlFile(this.event?.defaultImageHash);
    }
  }

  public gotoEvent(slug: string): void {
    this._commonService.navigate(['event', slug]);
  }
}
