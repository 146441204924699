import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepExamInstance } from '../../../common/models/evaluation/exam/step-exam-instance';
import { TranslateModule } from '@ngx-translate/core';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { StepSubmissionExam } from '../../../common/models/evaluation/exam/step-submission-exam';
import { StepType } from '../../../common/enums/course/step-type';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastService } from '../../../services/toast.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FILE_MIME_TYPE } from '../../../common/constants/file.constants';
import { StepExamMultiChoinceInstance } from '../../../common/models/evaluation/exam-multi-choice/step-exam-multi-choice-instance';
import { EvaluationStatus } from '../../../common/enums/evaluation/evaluation-status';
import { MatTabsModule } from '@angular/material/tabs';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { MepStorage } from '../../../services/abstract/mep-storage.abstract';
import { MepFile } from '../../../services/abstract/mep-file.abstract';
import { CourseEvaluationScoreComponent } from '../course-evaluation-score/course-evaluation-score.component';
import { Step } from '../../../common/models/course/step';
import { CommonService } from '../../../services/common.service';
import {
  faArrowLeft,
  faDownload,
  faFileLines,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-course-evaluation-preview',
    imports: [
        CommonModule,
        TranslateModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        SkeletonDirective,
        CourseEvaluationScoreComponent,
    ],
    templateUrl: './course-evaluation-preview.component.html',
    styleUrls: ['./course-evaluation-preview.component.scss']
})
export class CourseEvaluationPreviewComponent implements OnChanges {
  @Input() public examInstances:
    | StepExamInstance[]
    | StepExamMultiChoinceInstance[];
  @Input() step: Step;
  @Input() isScholarMode: boolean;

  @Output() clickBack = new EventEmitter<void>();

  public stepTypes = StepType;
  public fileMimeTypes = FILE_MIME_TYPE;
  public isLoading: boolean;

  public examInstancesFiltered:
    | StepExamInstance[]
    | StepExamMultiChoinceInstance[];

  public isDownloading: boolean;

  public backIcon = faArrowLeft;
  public downloadIcon = faDownload;
  public fileIcon = faFileLines;

  constructor(
    private _mepFile: MepFile,
    private _mepStorage: MepStorage,
    private _toastService: ToastService,
    public _commonService: CommonService
  ) {}

  public async ngOnChanges(): Promise<void> {
    if (this.examInstances && this.examInstances[0] !== undefined) {
      this.isLoading = true;

      if (this.isScholarMode) {
        this.examInstancesFiltered = this.examInstances.filter(
          (instance) => instance.status === EvaluationStatus.FINISHED
        );
      } else {
        this.examInstancesFiltered = this.examInstances;
      }

      const token = await this._mepStorage.get('access_token');

      this.examInstancesFiltered.forEach((examInstance) => {
        if (!examInstance) {
          return;
        }

        if (examInstance?.evaluator?.profile) {
          examInstance.evaluator.profile.evaluatorAvatarUrl =
            CommonHelper.getAvatarImage(examInstance.evaluator.profile.id);
        }

        examInstance.submissions =
          CommonHelper.orderByPropertyValue<StepSubmissionExam>(
            examInstance.submissions,
            'order'
          );

        examInstance.submissions = examInstance.submissions.map(
          (submission) => {
            if (submission.type === StepType.OPEN_TEXT) {
              return submission;
            }

            if (this.getFileType(submission.mimeType) !== 'image') {
              const extension = FILE_MIME_TYPE[submission.mimeType];
              submission.fileUrl = CommonHelper.getUrlFile(
                submission.fileHash,
                null,
                token,
                `format=mp4`
              );
            } else {
              submission.fileUrl = CommonHelper.getUrlFile(
                submission.fileHash,
                '&width=800',
                token
              );
            }
            return submission;
          }
        );
      });

      if (this._commonService.isPlatformBrowser) {
        setTimeout(() => {
          this.isLoading = false;
        }, 400);
      } else {
        this.isLoading = false;
      }
    }
  }

  public async downloadFile(
    url: string,
    fileName: string,
    mimeType: string
  ): Promise<void> {
    const extension = FILE_MIME_TYPE[mimeType];
    const fileNameAndExtension = fileName + '.' + extension;

    this.isDownloading = true;
    try {
      await this._mepFile.downloadFileFromUrl(url, fileNameAndExtension);
    } catch (error) {
      this._toastService.showError(error);
    }

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.isDownloading = false;
      }, 500);
    } else {
      this.isDownloading = false;
    }
  }

  public getFileType(mimeType: string): string {
    if (mimeType.includes('image')) {
      return 'image';
    }
    if (mimeType.includes('video')) {
      return 'video';
    }
    return 'other';
  }
}
