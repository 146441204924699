import { CommonService } from './../../../../services/common.service';
import { AuthenticationService } from './../../../../services/authentication.service';
import { PlatformService } from './../../../../services/platform.service';
import { FileEntity } from './../../../../common/models/files/file-entity';
import { ModalOptions } from './../../../../common/models/modal/modal-options';
import { SkeletonDirective } from './../../../../directives/skeleton.directive';
import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalComponent } from '../../modal/modal.component';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-file-viewer',
    imports: [CommonModule, ModalComponent, FontAwesomeModule, SkeletonDirective],
    templateUrl: './file-viewer-modal.component.html',
    styleUrls: ['./file-viewer-modal.component.scss']
})
export class FileViewerModalComponent {
  public modalOptions: ModalOptions;
  public isLoadingSkeleton = true;
  public isLoading: boolean;
  public leftIcon = faChevronLeft;
  public rightIcon = faChevronRight;
  public currentFileIndex = 0;

  public accessToken: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentFileId: number;
      files: FileEntity[];
    },
    public matDialogRef: MatDialogRef<FileViewerModalComponent>,
    public _platformService: PlatformService,
    private _authService: AuthenticationService,
    private _commonService: CommonService
  ) {
    this.modalOptions = {
      title: '',
      closeable: true,
      hideFooter: true,
      isDarkColor: true,
    };
  }

  public ngOnInit(): void {
    this.accessToken = this._authService.getToken();

    if (this.data?.currentFileId) {
      this.currentFileIndex = this.data.files.findIndex(
        (file) => file.id === this.data.currentFileId
      );
    }

    if (this._commonService.isPlatformBrowser) {
      setTimeout(() => {
        this.isLoadingSkeleton = false;
      }, 1000);
    } else {
      this.isLoadingSkeleton = false;
    }
  }

  public closeModal(): void {
    this.matDialogRef.close(false);
  }

  public async onLeftClick(): Promise<void> {
    if (this.data.files[this.currentFileIndex - 1]) {
      this.currentFileIndex--;
    }
  }

  public async onRightClick(): Promise<void> {
    if (this.data.files[this.currentFileIndex + 1]) {
      this.currentFileIndex++;
    }
  }
}
