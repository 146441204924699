import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import {
  faCloudArrowUp,
  faFile,
  faTrash,
  faUpload,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  AuthenticationService,
  LibraryService,
  ToastService,
} from '@meplis/services';
import { FileEntity } from '../../../common/models/files/file-entity';
import { LibraryItem } from '../../../common/models/library/library-item';
import { LibraryFileType } from '../../../common/enums/library/library-file-types';
import { LibraryTypes } from '../../../common/enums/library/library-types';
import { HttpProgressEvent } from '@angular/common/http';
import { ProgressBarComponent } from '../../common/progress-bar/progress-bar.component';
import { VideoPlayerComponent } from '../../common/video-player/video-player.component';
import { FILES_CONSTANTS_BY_TYPE } from '../../../common/constants/file.constants';
import { CommonHelper, ProgramFileType } from '@meplis/common';

@Component({
    selector: 'mec-program-media',
    imports: [
        CommonModule,
        TranslateModule,
        FontAwesomeModule,
        ProgressBarComponent,
        VideoPlayerComponent,
    ],
    templateUrl: './program-media.component.html',
    styleUrls: ['./program-media.component.scss']
})
export class ProgramMediaComponent implements OnChanges {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<{
    valid: boolean;
    programLog: ProgramLog;
  }>();

  public cloudIcon = faCloudArrowUp;
  public uploadIcon = faUpload;
  public videoIcon = faVideo;
  public fileIcon = faFile;
  public removeIcon = faTrash;

  public onDrag: boolean;
  public inputName: string;

  public files: FileEntity[] = [];
  public filesItems: LibraryItem[] = [];
  public currentFile: FileEntity;
  public showProgressBar: boolean;
  public progressValue: number;
  public fileName: string;
  public acceptTypes: string;

  public srcImage: string;

  constructor(
    private _libraryService: LibraryService,
    private _toastService: ToastService,
    private _authService: AuthenticationService
  ) {
    this.acceptTypes = this._filterAcceptTypes();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.programNode) {
      this.acceptTypes = this._filterAcceptTypes();

      if (this.programNode.log) {
        const fileId = this.programNode.log?.results[0]
          ?.value as unknown as number;
        this.srcImage = CommonHelper.getFileUrl(fileId, {
          access_token: this._authService.getToken(),
        });
      }
    }
  }

  private _filterAcceptTypes(): string {
    let typesExtensions: any;

    switch (this.programNode?.validation.type) {
      case ProgramFileType.image:
        typesExtensions = FILES_CONSTANTS_BY_TYPE['IMAGE']
          .extensions as unknown as any;
        break;
      case ProgramFileType.video:
        typesExtensions = FILES_CONSTANTS_BY_TYPE['VIDEO']
          .extensions as unknown as any;
        break;
      case ProgramFileType.audio:
        typesExtensions = FILES_CONSTANTS_BY_TYPE['AUDIO']
          .extensions as unknown as any;
        break;
      default:
        typesExtensions = FILES_CONSTANTS_BY_TYPE['DOCUMENT']
          .extensions as unknown as any;
    }

    const mimeTypesArray = Object.keys(typesExtensions).map((key) => {
      return typesExtensions[key].mimeType.join(',');
    });
    return mimeTypesArray.join(',');
  }

  private _validateTypes(type: string): boolean {
    return this.acceptTypes.includes(type);
  }

  public onDragLeave(): void {
    this.onDrag = false;
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    this.onDrag = false;

    if (!this._validateTypes(event.dataTransfer?.files[0].type)) {
      this._toastService.showError(
        'COMPONENT.CHAIN_MEDIA.VALIDATION.WRONG_FILE_TYPE',
        undefined,
        5000,
        undefined,
        { allowedFileType: this.programNode?.validation?.type }
      );
      return;
    }

    this._uploadFiles(event);
  }

  public onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.onDrag = true;
  }

  public clearFile(): void {
    this.currentFile = undefined;
    this.showProgressBar = undefined;
    this.srcImage = undefined;
    this.progressValue = 0;
    this.files = [];

    this._selectItem(undefined, false);
  }

  public loadFile(event: any): void {
    this._uploadFiles(event);
  }

  private _uploadFiles(event: any): void {
    const fileName =
      event?.dataTransfer?.files[0].name ||
      event?.target?.files[0].name ||
      event?.files[0].name;

    this._libraryService.uploadFiles(
      event,
      (libraryFile: FileEntity, libraryFilesConfirmResult: FileEntity[]) =>
        this._confirmFile(libraryFile, libraryFilesConfirmResult),
      (progress: HttpProgressEvent) => {
        this._onProgressChange(fileName, progress.loaded, progress.total);
      },
      this.files,
      (currentFile: FileEntity) => this._onGetCurrentFile(currentFile)
    );
  }

  private _onGetCurrentFile(currentFile: FileEntity): void {
    this.currentFile = currentFile;
  }

  private _onProgressChange(
    fileName: string,
    progressValue: number,
    totalValue: number
  ): void {
    if (!this.showProgressBar) {
      this.showProgressBar = true;
      this.fileName = fileName;
    }

    this.progressValue = Math.floor((progressValue / totalValue) * 100);
  }

  private async _confirmFile(
    libraryFile: FileEntity,
    libraryFileConfirmResult: FileEntity[]
  ): Promise<void> {
    try {
      if (libraryFileConfirmResult?.length) {
        libraryFile.id = libraryFileConfirmResult[0].id;
        libraryFile.isLoading = false;

        const libraryItemToAdd: LibraryItem = {
          fileId: libraryFileConfirmResult[0].id,
          fileType: libraryFileConfirmResult[0].type,
          libraryFileType: LibraryFileType.FILE_BASED,
          type: LibraryTypes.file,
          defaultImage: null,
          tags: [],
          content: {
            title: libraryFileConfirmResult[0].filename,
            description: libraryFileConfirmResult[0].filename,
          },
        };

        this.filesItems.push(libraryItemToAdd);
        this._selectItem(libraryItemToAdd.fileId, true);
      }
    } catch (error) {
      this._toastService.showError(error);
    }

    this.showProgressBar = false;
  }

  private _selectItem(value: number, valid: boolean): void {
    if (!value) {
      valid = false;
    }

    const programLog = {
      to: this.programNode.id,
      at: new Date().toISOString(),
      results: [
        { name: this.programNode.variable.name, value: value.toString() },
      ],
    };

    this.onSelectItemChange.emit({ valid, programLog });
  }
}
