import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'mec-authentication-title',
    imports: [CommonModule, MatExpansionModule, TranslateModule],
    templateUrl: './authentication-title.component.html',
    styleUrls: ['./authentication-title.component.scss']
})
export class AuthenticationTitleComponent {
  @Input() title: string;

  public accountLogo = '../../assets/images/meplis-account.png';
  public panelOpenState: boolean;
}
