import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArticleMenu } from '../../../common/enums/article/article-menu';
import { ArticleVisibility } from '../../../common/enums/article/article-visibility';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { HubInstance } from '../../../common/models/hub/hub-instance';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiPagesService } from '../../../services/api/api-pages.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { InstanceService } from '../../../services/instance.service';
import { ToastService } from '../../../services/toast.service';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';
import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { CustomHubComponent } from '../../../common/models/hub/custom-hub-component';
import { Hub } from '../../../services/abstract/hub.abstract';
import { MenuData } from '../../../common/models/side-bar/menu-data';
import { MenuItem } from '../../../common/models/side-bar/menu-item';

@Component({
    selector: 'mec-footer',
    imports: [
        CommonModule,
        TranslateModule,
        SkeletonDirective,
        FontAwesomeModule,
    ],
    providers: [ApiPagesService],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() public policiesMenus: CustomHubComponent[];

  public logo: string;
  public instance: HubInstance;
  public isLoadingLogo: boolean;
  public hiddenLogo: boolean;
  public isLoadingInstance: boolean;
  public isLoadingMenus: boolean;
  public isLogged: boolean;

  public supportEmail: string;

  public facebookIcon = faFacebook;
  public instagramIcon = faInstagram;
  public linkedinIcon = faLinkedin;

  public blockedLinks = [
    'events-view',
    'news-view',
    'page-not-found',
    'register',
    'header',
    'footer',
  ];

  public menuData: MenuData = {
    menuItems: [],
  };

  constructor(
    public _commonService: CommonService,
    private _apiPagesService: ApiPagesService,
    private _authService: AuthenticationService,
    private _instanceService: InstanceService,
    private _toastService: ToastService,
    private _hubService: Hub
  ) {}
  public ngOnChanges(): void {}

  public async ngOnInit(): Promise<void> {
    this.isLogged = this._authService.isLogged;
    this.isLoadingLogo = true;
    this.isLoadingMenus = true;
    this.isLoadingInstance = true;
    this._getPoliciesMenus();
    await this._getIntance();
    this._getPagesMenus();
  }

  private async _getIntance(): Promise<void> {
    try {
      this.instance = await this._instanceService.getInstance();

      if (this.instance.defaultImageHash) {
        this.logo = CommonHelper.getUrlFile(this.instance.defaultImageHash);
      }

      if (this.instance?.content?.support) {
        this.supportEmail = this._getSupportEmail(
          this.instance.content.support
        );
      }
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingInstance = false;
  }

  private _getSupportEmail(suportString: string): string {
    const email = suportString.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    );

    if (email) {
      email.toString();
    } else {
      return;
    }
  }

  private async _getPagesMenus(): Promise<void> {
    try {
      const instance: HubInstance = JSON.parse(JSON.stringify(this.instance));

      let menusItems = CommonHelper.orderByPropertyValue(
        instance?.menus,
        'order'
      );

      menusItems = this._getFlatMenus(menusItems);

      menusItems = menusItems.map((menu) => {
        if (menu.tag) {
          menu.targetSlug = `tags/${menu.targetSlug}`;
        }
        return menu;
      });

      const visibility = this.isLogged
        ? ArticleVisibility.LOGGED_IN
        : ArticleVisibility.NOT_LOGGED_IN;

      const menusFiltered = menusItems.filter(
        (item: MenuItem) =>
          (item.menu === ArticleMenu.BOTH ||
            item.menu === ArticleMenu.BOTTOM_MENU) &&
          (item.visibility === visibility ||
            item.visibility === ArticleVisibility.BOTH)
      );

      this.menuData.menuItems = menusFiltered;
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoadingMenus = false;
  }

  private _getFlatMenus(items: MenuItem[]): MenuItem[] {
    const flatMenus: MenuItem[] = [];

    items.forEach((item) => {
      if (item?.items?.length) {
        const flattenChildren = this._getFlatMenus(item.items);
        flatMenus.push(...flattenChildren);
      } else {
        flatMenus.push(item);
      }
    });

    return flatMenus;
  }

  private _getPoliciesMenus(): void {
    let components = this._hubService.getComponents() as CustomHubComponent;

    const policies = Object.values(components).filter(
      (component) =>
        component.slug === 'cookie-policy' ||
        component.slug === 'legal-notice' ||
        component.slug === 'disclaimer' ||
        component.slug === 'privacy-policy'
    );

    if (!policies) {
      return;
    }

    this.policiesMenus = policies;
    this.menuData.menuItems = [...this.menuData.menuItems, ...policies];
  }

  private _filterBlockedItems(slug: string): boolean {
    return this.blockedLinks.some((link) => link === slug);
  }

  public imageLogoError(): void {
    this.hiddenLogo = true;
  }

  public imageLogoLoaded(): void {
    this.isLoadingLogo = false;
    this.hiddenLogo = false;
  }

  public openMailLink(): void {
    // window.open('mailto:' + this.supportEmail);
    this._commonService.windowOpen('mailto:' + this.supportEmail);
  }

  public goToPage(path: string): void {
    if (path === 'logout') {
      const logoutResult = this._authService.logOut(Routes.home.fullPath);
      if (logoutResult) {
        this._toastService.showSuccess('AUT.FEEDBACK.OPEN.LOGOUT.200.SUCCESS');
      }
      return;
    }

    this._commonService.navigate([path]);
  }
}
