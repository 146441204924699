import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AutocompleteItem } from '../../../common/models/common/autocomplete/autocomplete-item';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-autocomplete',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        FontAwesomeModule,
        TranslateModule,
    ],
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnChanges {
  @ViewChild('inputAutocomplete') public inputAutocomplete: ElementRef;

  @Input() controlName: string;
  @Input() placeholder?: string;
  @Input() formGroup: FormGroup;
  @Input() hideSearchIcon: boolean;
  @Input() items: AutocompleteItem[];
  @Input() isDynamicList: boolean;
  @Output() optionSelectedChange = new EventEmitter<AutocompleteItem>();
  @Output() searchFilterChange = new EventEmitter<string>();

  public searchIcon = faMagnifyingGlass;

  constructor(private _commonService: CommonService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formGroup']) {
      this.formGroup.controls[this.controlName].valueChanges.subscribe(
        (search) => {
          if (this.isDynamicList) {
            this.searchFilterChange.emit(search);
          } else {
            this.items = this._filter(search);
          }
        }
      );

      // this.formGroup.controls[this.controlName].valueChanges.subscribe(
      //   (search) => {}
      // );

      if (this._commonService.isPlatformBrowser) {
        setTimeout(() => {
          this.inputAutocomplete.nativeElement.focus();
        }, 200);
      }
    }
    console;
  }

  public optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.optionSelectedChange.emit(event.option.value);
  }

  public displayName(item: AutocompleteItem): string {
    return item && item.title ? item.title : '';
  }

  private _filter(value: string): AutocompleteItem[] {
    const filterValue = value?.toLowerCase();

    return this.items.filter((item) =>
      item.title.toLowerCase().includes(filterValue)
    );
  }
}
