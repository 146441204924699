import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { Subscription } from 'rxjs';
import { SocialNetWorkService } from '../../../services/social-network.service';
import { ModalService } from '../../../services/modal.service';
import { SocialPublicationCreateModalComponent } from '../social-publication-create-modal/social-publication-create-modal.component';
import { CommonService } from '../../../services/common.service';
import { faLink, faImages, faFileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-social-publication-create',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FontAwesomeModule,
    ],
    templateUrl: './social-publication-create.component.html',
    styleUrls: ['./social-publication-create.component.scss']
})
export class SocialPublicationCreateComponent implements OnChanges, OnDestroy {
  @Input() public socialNetWorkInstaceId: string;
  @Input() public profileId: string;

  @Output() public socialNetWorkCreateChange = new EventEmitter<void>();

  public avatarUrl = '';

  public isMobile: boolean;

  public linkIcon = faLink;
  public imagesIcon = faImages;
  public fileIcon = faFileAlt;

  public isMobileChangeSubscription: Subscription;

  constructor(
    private _socialNetWorksService: SocialNetWorkService,
    private _modalService: ModalService,
    private _commonService: CommonService
  ) {
    this.isMobile = this._commonService.isMobile;
    this.isMobileChangeSubscription =
      this._commonService.onIsMobileChange.subscribe(() => {
        this.isMobile = this._commonService.isMobile;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.profileId) {
      this.avatarUrl = CommonHelper.getAvatarImage(this.profileId);
    }
  }

  public ngOnDestroy(): void {
    this.isMobileChangeSubscription.unsubscribe();
  }

  public async openCreateModal(
    initalInputType?: 'link' | 'image-video' | 'file'
  ): Promise<void> {
    const modalResult = await this._modalService.openModal<
      SocialPublicationCreateModalComponent,
      {
        profileId: string;
        socialNetWorkInstaceId?: string;
        initalInputType?: 'link' | 'image-video' | 'file';
      }
    >(
      SocialPublicationCreateModalComponent,
      {
        profileId: this.profileId,
        socialNetWorkInstaceId: this.socialNetWorkInstaceId,
        initalInputType,
      },
      {},
      800
    );

    modalResult.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._socialNetWorksService.socialPublicationsReloadSubject.next(true);
      }
    });
  }
}
