import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../../common/modal/modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppConstants } from '../../../common/constants/app.constants';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiCoursesV2Service } from '../../../services/api/api-courses-v2.service';
import { ToastService } from '../../../services/toast.service';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { CommonService } from '../../../services/common.service';
import { ApiCoursesV1Service } from '../../../services/api/api-courses-v1.service';

@Component({
    selector: 'mec-course-subscribe-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ModalComponent,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        SkeletonDirective,
    ],
    providers: [ApiCoursesV2Service, ApiCoursesV1Service],
    templateUrl: './course-subscribe-modal.component.html',
    styleUrls: ['./course-subscribe-modal.component.scss']
})
export class CourseSubscribeModalComponent implements OnInit {
  public modalOptions: ModalOptions;
  public isLoading: boolean;
  public isSaving: boolean;
  public enrollForm: FormGroup;
  public path: string;
  public legalDocuments: { href: string; title: string }[] = [];

  constructor(
    private _translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { courseId: number; courseSlug: string },
    public matDialogRef: MatDialogRef<CourseSubscribeModalComponent>,
    private _apiCourseV2Service: ApiCoursesV2Service,
    private _apiCourseV1Service: ApiCoursesV1Service,
    private _toastService: ToastService,
    private _formBuilder: FormBuilder,
    private _commonService: CommonService
  ) {
    this.enrollForm = this._formBuilder.group({
      agreement: [false, Validators.requiredTrue],
    });

    this.path = `course/${this.data.courseSlug}/`; //todo it'll be used to show policies from a course

    this.modalOptions = {
      title: this._translateService.instant('SUBSCRIBE_COURSE'),
      closeable: true,
      positiveButtonLabel: 'SUBSCRIBE',
      showPositiveButton: true,
      showNegativeButton: false,
      showSecondaryNegativeButton: false,
      showSecondaryPositiveButton: false,
      hideFooter: false,
    };
  }

  public async ngOnInit(): Promise<void> {
    await this.getLegalDocuments();
  }

  public async getLegalDocuments(): Promise<void> {
    this.isLoading = true;
    try {
      const pages = await this._apiCourseV2Service.getPages(
        this.data.courseSlug
      );

      pages.forEach((page) => {
        const legalDocument = AppConstants.legalDocuments.find(
          (l) => l.href === page.slug
        );

        if (legalDocument) {
          this.legalDocuments.push({
            href: legalDocument.href,
            title: legalDocument.title,
          });
        }
      });
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  public async subscribeCourse(): Promise<void> {
    this.isSaving = true;
    try {
      await this._apiCourseV1Service.enrollCourse(this.data.courseId);
      this.closeModal(true);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isSaving = false;
  }

  public closeModal(save: boolean): void {
    this.matDialogRef.close(save);
  }

  public goToPage(path: string): void {
    this._commonService.navigate([path], null, true);
  }
}
