import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationExtras } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Course } from '../../../common/models/course/courses';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { CoursesService } from '../../../services/courses.service';
import { ModalService } from '../../../services/modal.service';
import { CourseSubscribeModalComponent } from '../course-subscribe-modal/course-subscribe-modal.component';
import { CommonService } from '../../../services/common.service';
import { ApiCoursesV2Service } from '../../../services/api/api-courses-v2.service';
import { Hub } from '../../../services/abstract/hub.abstract';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'mec-course-card',
    imports: [
        CommonModule,
        SkeletonDirective,
        TranslateModule,
        NgCircleProgressModule,
        FontAwesomeModule,
    ],
    providers: [ApiCoursesV2Service],
    templateUrl: './course-card.component.html',
    styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnChanges {
  @Input() public course: Course;

  @Output() public refreshCoursesList = new EventEmitter<void>();

  public hiddenImage: boolean;
  public imageUrl: string;

  public isLoadingImage = true;

  public hasShowGoToClassroom: boolean;
  public hasShowGoToSubscribe: boolean;
  public hasShowInvitedOnly: boolean;
  public isExpiredInvitation: boolean;

  public detailIcon = faAngleRight;

  constructor(
    public _commonService: CommonService,
    private _courseService: CoursesService,
    private _hubService: Hub,
    private _modalService: ModalService
  ) {}

  public ngOnChanges(): void {
    if (this.course?.slug) {
      this.imageUrl = CommonHelper.getUrlFile(
        this.course.webBigSizeImageHash,
        '&width=1328'
      );
      this._handleCourseAccess();
    }
  }

  public async _handleCourseAccess(): Promise<void> {
    this.isExpiredInvitation = await this._courseService.isExpired(this.course);
    this.hasShowGoToClassroom = await this._courseService.hasShowGoToClassroom(
      this.course
    );
    this.hasShowGoToSubscribe = await this._courseService.hasShowGoToSubscribe(
      this.course
    );
    this.hasShowInvitedOnly = await this._courseService.hasShowInvitedOnly(
      this.course
    );
  }

  public goToCourseStep(stepId: number): void {
    const extras: NavigationExtras = {
      queryParams: {
        courseId: this.course.id,
      },
    };
    this._commonService.navigate(
      ['course', this.course.slug, 'step', stepId as unknown as string],
      extras
    );
  }

  public goToCourseDetail(): void {
    this._commonService.navigate(['course', this.course.slug]);
  }

  public async openSubscriptionModal(): Promise<void> {
    const modalRef = await this._modalService.openModal(
      CourseSubscribeModalComponent,
      { courseId: this.course.id, courseSlug: this.course.slug }
    );

    modalRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.refreshCoursesList.emit();
      }
    });
  }
}
