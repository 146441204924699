import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import videojs from 'node_modules/video.js';

import { ApiFilesV2Service } from '../../../services/api/api-files-v2.service';
import { ApiFilesV3Service } from '../../../services/api/api-files-v3.service';
import { AudioItem } from '../../../common/models/event/audio-item';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { EventService } from '../../../services/event.service';
import { EventStatus } from '../../../common/enums/event/event-status';
import { Language } from '../../../common/models/common/language/language';
import { SessionVideoOptions } from '../../../common/models/event/session-video-options';
import { VideoSrc } from '../../../common/models/common/video/video-src';
import { i18nService } from '../../../services/i18n.service';

@Component({
    selector: 'mec-video-player',
    imports: [CommonModule],
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sourceVideo: VideoSrc;
  @Input() coverVideoUrl: string;
  @Input() sessionOptions: SessionVideoOptions;

  public options: any = {
    fluid: true,
    aspectRatio: '16:9',
    autoplay: false,
  };

  @ViewChild('target', { static: true })
  target: ElementRef;

  public player: any;

  constructor(
    private _apiFilesV2Service: ApiFilesV2Service,
    private _apiFilesV3Service: ApiFilesV3Service,
    private _elementRef: ElementRef,
    private _eventService: EventService,
    private _i18nService: i18nService
  ) {
    this._eventService.playerUpdateSubject.subscribe((audioItem) => {
      this.target.nativeElement.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
      this.setItemVideoPlayer(audioItem);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.sourceVideo) {
      this._handleDefaultVideo();
      return;
    }

    if (this.sessionOptions?.eventStatus === EventStatus.ON_DEMAND) {
      this._handleOnDemandPlayer();
    } else if (this.sessionOptions?.eventStatus === EventStatus.LIVE) {
      this._handleLivePlayer();
    }
  }

  public ngOnDestroy(): void {
    if (this.player) {
      this._destroySubtitles();
      this.player.dispose();
    }
  }

  private _onPlayerReady(): void {}

  public ngOnInit(): void {}

  private _destroySubtitles() {
    if (!this.player) return;

    const textTracks = this.player.textTracks();

    if (!textTracks.tracks_.length) return;

    textTracks.tracks_.forEach((textTrack: any) => {
      if (textTrack.kind === 'subtitles') {
        this.player.removeRemoteTextTrack(textTrack);
      }
    });
  }

  private _handleDefaultVideo(): void {
    this.options.sources = this.sourceVideo;
    this.options.poster = this.coverVideoUrl;
    this.player = videojs(this.target.nativeElement, this.options, () =>
      this._onPlayerReady()
    );
  }

  private _handleLivePlayer(): void {
    this.options.liveui = true;
    this.options.sources = [this.sessionOptions.eventLiveUrl];

    this.player = videojs(this.target.nativeElement, this.options, () =>
      this._onPlayerReady()
    );
  }

  private _handleOnDemandPlayer(): void {
    const defaultAudioItem =
      this.sessionOptions.audios.items.find(
        (item) => item.language === this._i18nService.language
      ) || this.sessionOptions.audios.items[0];

    this.options.sources = this._getVODSourceVideo(defaultAudioItem.videoUrl);
    this.options.poster = this._getVODPosterImage(defaultAudioItem.videoUrl);

    this.player = videojs(this.target.nativeElement, this.options, () => {
      this._onPlayerReady();
      this._handleSubtitles();
    });

    this._handleLanguageOptions();
  }

  private _handleLanguageOptions(): void {
    videojs.registerComponent('CustomMenuButton', CustomMenuButton);

    const languageItems: {
      name: string;
      id: string;
      clickHandler: () => void;
    }[] = [];

    this.sessionOptions.audios.items.forEach((item) => {
      languageItems.push({
        name: item.description,
        id: item.language,
        clickHandler: () => this.clickLanguage(item.language),
      });
    });

    const customMenuButton = new CustomMenuButton(this.player, {
      myItems: languageItems,
    }) as any;
    customMenuButton.menuButton_.el_.innerHTML =
      '<i style="font-size: 15px" class="fa-solid fa-globe"></i>';

    this.player.controlBar.addChild(customMenuButton);
  }

  private async _handleSubtitles(): Promise<void> {
    this._destroySubtitles();

    const source = this.player.currentSrc();
    if (!source) return;

    try {
      const urlExtracted = CommonHelper.extractFileUrl(source);
      const subtitles = await this._apiFilesV3Service.getFileSubtitlesByUrl(
        urlExtracted
      );

      if (!subtitles) return;

      subtitles.forEach((subtitle) => {
        this.player.addRemoteTextTrack(
          {
            default: subtitle.language === this._i18nService.language,
            kind: 'subtitles',
            label: subtitle.description,
            src: subtitle.url,
            srclang: subtitle.language,
          },
          false
        );
      });
    } catch (_handleSubtitlesError) {
      console.error(_handleSubtitlesError);
    }
  }

  private _getVODSourceVideo(videoUrl: string): { src: string; type: string } {
    const source = {
      src: videoUrl + '?quality=720&format=mp4',
      type: 'video/mp4',
    };

    return source;
  }

  private _getVODPosterImage(imagePosterUrl?: string): string {
    imagePosterUrl = imagePosterUrl + '?format=thumbnail&width=1024';

    return imagePosterUrl;
  }

  public selectSubtitle(language: Language) {
    if (this.player) return;

    const textTracks = this.player.textTracks();

    if (!textTracks.tracks_.length) return;

    textTracks.tracks_.forEach((textTrack: any) => {
      if (textTrack.kind === 'textTracks') {
        textTrack.mode = !language
          ? 'disabled'
          : textTrack.language === language
          ? 'showing'
          : 'disabled';
      }
    });
  }

  public setItemVideoPlayer(audioItem: AudioItem): void {
    this.player.src(this._getVODSourceVideo(audioItem.videoUrl));
    this.player.poster(this._getVODPosterImage(audioItem.videoUrl));

    const currentTime = audioItem.currentTime || 0;

    this.player.play();
    this.player.currentTime(currentTime);
  }

  public clickLanguage(language: string): void {
    const audioItem = this.sessionOptions.audios.items.find(
      (item) => item.language === language
    );
    audioItem.currentTime = this.player.currentTime();
    this.setItemVideoPlayer(audioItem);
  }
}

const MenuButton = videojs.getComponent('MenuButton');
const MenuItem = videojs.getComponent('MenuItem');

class CustomMenuButton extends MenuButton {
  constructor(player: any, options_: object) {
    super(player, options_);
  }
  createItems() {
    const player = this.player();
    return this.options_.myItems.map((i: any) => {
      const item = new MenuItem(player, {}) as unknown as any;

      item.el_.innerHTML = `<span>${i.name}</span>`;
      item.handleClick = i.clickHandler;

      return item;
    });
  }
}
