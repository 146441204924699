import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  public onStepDependecyChange = new Subject<string>();

  constructor() {}

  public async setStepDependency(dependencyId?: string): Promise<void> {
    this.onStepDependecyChange.next(dependencyId);
  }
}
