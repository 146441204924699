import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileEntity } from '../../../common/models/profile/profile-entity';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonService } from '../../../services/common.service';
import { noop } from 'rxjs';

@Component({
    // selector: 'mec-mentions-list',
    selector: 'development-mentions-list',
    imports: [CommonModule, MatProgressSpinnerModule],
    templateUrl: './mentions-list.component.html',
    styleUrls: ['./mentions-list.component.scss']
})
export class MentionsListComponent implements OnInit, OnChanges {
  @Input() itemsList: ProfileEntity[];
  @Output() itemSelected = new EventEmitter<ProfileEntity>();

  @ViewChild('list', { static: true }) list: ElementRef;

  public isLoading: boolean;
  public hidden: boolean;
  private coords: { top: number; left: number } = { top: 0, left: 0 };

  constructor(
    private _commonService: CommonService,
    private _element: ElementRef
  ) {}

  public ngOnInit(): void {
    noop();
  }

  public getAvatarimage(avatarId: number): string {
    return CommonHelper.getAvatarImage(avatarId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    noop();
  }

  public selectItem(item: ProfileEntity): void {
    this.itemSelected.emit(item);
  }

  public position(nativeParentElement: HTMLInputElement) {
    // const left = nativeParentElement.offsetLeft;
    // const top = nativeParentElement.offsetTop;

    const offset = this.getBlockCursorDimensions(nativeParentElement).height;

    this.coords = this._getCaretCoordinates(
      nativeParentElement,
      nativeParentElement.selectionStart,
      null
    );
    this.coords.top =
      nativeParentElement.offsetTop +
      this.coords.top -
      nativeParentElement.scrollTop +
      offset;
    this.coords.left =
      nativeParentElement.offsetLeft +
      this.coords.left -
      nativeParentElement.scrollLeft;

    this.list.nativeElement.style.left = this.coords.left + 'px';
    this.list.nativeElement.style.top = this.coords.top + 'px';
  }

  public properties = [
    'direction', // RTL support
    'boxSizing',
    'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
    'height',
    'overflowX',
    'overflowY', // copy the scrollbar for IE

    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderStyle',

    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',

    // https://developer.mozilla.org/en-US/docs/Web/CSS/font
    'fontStyle',
    'fontVariant',
    'fontWeight',
    'fontStretch',
    'fontSize',
    'fontSizeAdjust',
    'lineHeight',
    'fontFamily',

    'textAlign',
    'textTransform',
    'textIndent',
    'textDecoration', // might not make a difference, but better be safe

    'letterSpacing',
    'wordSpacing',

    'tabSize',
    'MozTabSize',
  ];

  private _getCaretCoordinates(element: any, position: any, options: any) {
    if (!this._commonService.isPlatformBrowser) {
      // throw new Error(
      //   'textarea-caret-position#getCaretCoordinates should only be called in a browser'
      // );

      console.error(
        'textarea-caret-position#getCaretCoordinates should only be called in a browser'
      );

      return {
        top: 0,
        left: 0,
        height: 0,
      };
    }

    const debug = (options && options.debug) || false;
    if (debug) {
      const el = document.querySelector(
        '#input-textarea-caret-position-mirror-div'
      );
      if (el) el.parentNode.removeChild(el);
    }

    // The mirror div will replicate the textarea's style
    const div = document.createElement('div');
    div.id = 'input-textarea-caret-position-mirror-div';
    document.body.appendChild(div);

    const style = div.style;
    const computed = window.getComputedStyle
      ? window.getComputedStyle(element)
      : element.currentStyle; // currentStyle for IE < 9
    const isInput = element.nodeName === 'INPUT';

    // Default textarea styles
    style.whiteSpace = 'pre-wrap';
    if (!isInput) style.wordWrap = 'break-word'; // only for textarea-s

    // Position off-screen
    style.position = 'absolute'; // required to return coordinates properly
    if (!debug) style.visibility = 'hidden'; // not 'display: none' because we want rendering

    // Transfer the element's properties to the div
    this.properties.forEach((prop: any) => {
      if (isInput && prop === 'lineHeight') {
        // Special case for <input>s because text is rendered centered and line height may be != height
        if (computed.boxSizing === 'border-box') {
          const height = parseInt(computed.height);
          const outerHeight =
            parseInt(computed.paddingTop) +
            parseInt(computed.paddingBottom) +
            parseInt(computed.borderTopWidth) +
            parseInt(computed.borderBottomWidth);
          const targetHeight = outerHeight + parseInt(computed.lineHeight);
          if (height > targetHeight) {
            style.lineHeight = height - outerHeight + 'px';
          } else if (height === targetHeight) {
            style.lineHeight = computed.lineHeight;
          } else {
            style.lineHeight = '0';
          }
        } else {
          style.lineHeight = computed.height;
        }
      } else {
        style[prop] = computed[prop];
      }
    });

    style.overflow = 'hidden'; // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'

    div.textContent = element.value.substring(0, position);
    // The second special handling for input type="text" vs textarea:
    // spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
    if (isInput) div.textContent = div.textContent.replace(/\s/g, '\u00a0');

    const span = document.createElement('span');
    // Wrapping must be replicated *exactly*, including when a long word gets
    // onto the next line, with whitespace at the end of the line before (#7).
    // The  *only* reliable way to do that is to copy the *entire* rest of the
    // textarea's content into the <span> created at the caret position.
    // For inputs, just '.' would be enough, but no need to bother.
    span.textContent = element.value.substring(position) || '.'; // || because a completely empty faux span doesn't render at all
    div.appendChild(span);

    const coordinates = {
      top: span.offsetTop + parseInt(computed['borderTopWidth']),
      left: span.offsetLeft + parseInt(computed['borderLeftWidth']),
      height: parseInt(computed['lineHeight']),
    };

    if (debug) {
      span.style.backgroundColor = '#aaa';
    } else {
      document.body.removeChild(div);
    }

    return coordinates;
  }

  private getBlockCursorDimensions(nativeParentElement: HTMLInputElement) {
    if (!this._commonService.isPlatformBrowser) {
      return {
        height: 1,
        width: 1,
      };
    }

    const parentStyles = window.getComputedStyle(nativeParentElement);
    return {
      height: parseFloat(parentStyles.lineHeight),
      width: parseFloat(parentStyles.fontSize),
    };
  }
}
