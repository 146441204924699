import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommonService } from '../../../services/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { SkeletonDirective } from '../../../directives/skeleton.directive';

@Component({
    selector: 'mec-banner',
    imports: [CommonModule, TranslateModule, SkeletonDirective],
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() public isLoadingInstance: boolean;
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public bannerImageUrl: string;
  @Input() public isShort: boolean;

  public hiddenImage: boolean;
  public isLoadingImage = true;

  constructor(
    public authService: AuthenticationService,
    private _commonService: CommonService
  ) {}

  public goToPage(path: string): void {
    this._commonService.navigate([path]);
  }

  public imageError(): void {
    this.hiddenImage = true;
  }

  public imageLoaded(): void {
    this.isLoadingImage = false;
    this.hiddenImage = false;
  }
}
