import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { MatRadioModule } from '@angular/material/radio';
import { ProgramAnswer } from '../../../common/models/care-monitor/program-answer';
import { ProgramValue } from '../../../common/models/care-monitor/program-value';
import { ProgramVariable } from '../../../common/models/care-monitor/program-variable';
import { ProgramLog } from '@meplis/common';

@Component({
    selector: 'mec-program-matrix-radio',
    imports: [CommonModule, MatRadioModule],
    templateUrl: './program-matrix-radio.component.html',
    styleUrls: ['./program-matrix-radio.component.scss']
})
export class ProgramMatrixRadioComponent {
  @Input() public programNode: ProgramNode;
  @Output() public onSelectItemChange = new EventEmitter<ProgramLog>();

  public answers: ProgramAnswer[] = [];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.programNode) {
    }
  }

  public selectItem(programVariable: ProgramVariable, value: string): void {
    const programAnswer: ProgramAnswer = {
      name: programVariable.name,
      value,
    };

    let programAnswerIndex = this.answers.findIndex(
      (answer) => answer.name === programVariable.name
    );

    if (programAnswerIndex >= 0) {
      this.answers[programAnswerIndex] = programAnswer;
    } else {
      this.answers.push(programAnswer);
    }

    if (this.answers.length === this.programNode.chains.length) {
      const log = {
        to: this.programNode.id,
        at: new Date().toISOString(),
        results: this.answers,
      };

      this.onSelectItemChange.emit(log);
    }
  }
}
