import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormType } from '../../../common/enums/forms/custom-form-type';
import { ApiFormsService } from '../../../services/api/api-forms.service';
import { ToastService } from '../../../services/toast.service';
import { CustomForm } from '../../../common/models/forms/custom-form';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomFormFieldType } from '../../../common/enums/forms/custom-form-field-type';
import { MatSelectModule } from '@angular/material/select';
import { CustomFormField } from '../../../common/models/forms/custom-form-field';
import { InstanceService } from '../../../services/instance.service';
import { HubInstance } from '../../../common/models/hub/hub-instance';
import { TranslateModule } from '@ngx-translate/core';
import { AuthenticationConstants } from '../../../common/constants/authentication.constants';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomFormSubmission } from '../../../common/models/forms/custom-form-submission';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonService } from '../../../services/common.service';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ProfileCustomForm } from '../../../common/models/forms/profile-custom-form';

@Component({
    selector: 'mec-custom-form',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatCheckboxModule,
        TranslateModule,
        MatProgressSpinnerModule,
        SkeletonDirective,
    ],
    templateUrl: './custom-form.component.html',
    styleUrls: ['./custom-form.component.scss']
})
export class CustomFormComponent implements OnChanges, OnInit {
  @Input() removePadding: boolean;
  @Input() type: CustomFormType;
  @Input() isEditMode: boolean;
  @Input() notAnswered: boolean;
  @Input() formId: string;
  @Input() customForms: CustomForm[] = [];
  @Input() disableSave: boolean;

  @Output() hasCustomForms = new EventEmitter<boolean>();
  @Output() formSavedChanged = new EventEmitter<CustomFormSubmission[]>();

  public profileCustomForms: ProfileCustomForm[];
  public hubInstance: HubInstance;
  public legalDocuments = AuthenticationConstants.authenticationLegalDocuments;
  public customFormFieldTypes = CustomFormFieldType;
  public acceptedTerms: boolean;
  public formsValid: boolean;
  public isSaving: boolean;
  public loading: boolean;

  constructor(
    private _apiFormsService: ApiFormsService,
    private _toastService: ToastService,
    private _formBuilder: FormBuilder,
    private _intanceService: InstanceService,
    private _commonService: CommonService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.hubInstance = await this._intanceService.getInstance();
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.customForms?.length) {
      this._buildForms();
      this.hasCustomForms.emit(this.customForms?.length > 0);

      return;
    }

    if (this.isEditMode) {
      await this._getFormByType();
      await this._getFormsByUser();
      this.hasCustomForms.emit(this.customForms?.length > 0);

      return;
    }

    if (this.formId || this.type) {
      if (this.formId) {
        this._getFormById();
        this.hasCustomForms.emit(this.customForms?.length > 0);

        return;
      }
      this._getFormByType();
    }
  }

  public validateForm(): void {
    this.customForms.forEach((customForm) => {
      this._clearAllValidators(customForm);

      customForm.fields.forEach((field) => {
        if (!field?.conditions?.length) {
          this._updateFieldValidators(field, customForm);
        }
        this._verifiyIfFieldHasCondition(customForm, field);
      });
    });
  }

  private _verifiyIfFieldHasCondition(
    customForm: CustomForm,
    field: CustomFormField
  ): void {
    if (!field.conditions || !field.conditions.length) {
      field.isValidField = true;
    }

    field?.conditions?.forEach((condition) => {
      field.isValidField = false;

      if (!customForm?.form?.contains(condition.field)) {
        return;
      }

      const control = customForm?.form?.get(condition.field);

      if (control.value === condition.value) {
        field.isValidField = true;

        if (field.required) {
          this._updateFieldValidators(field, customForm);
        }
      }
    });

    this.formsValid = customForm.form.valid;
  }

  private _clearAllValidators(customForm: CustomForm): void {
    for (const key in customForm.form.controls) {
      customForm.form.get(key).clearValidators();
      customForm.form.get(key).updateValueAndValidity();
    }
  }

  private _updateFieldValidators(
    field: CustomFormField,
    customForm: CustomForm
  ): void {
    const control = customForm?.form?.get(field.name);

    control.clearValidators();
    control.updateValueAndValidity();
  }

  private _buildForms(): void {
    this.customForms.forEach((customForm) => {
      const formGroup = this._formBuilder.group({});

      customForm.fields.forEach((field) => {
        const requiredValidator =
          field.required && !field.conditions?.length
            ? Validators.required
            : null;

        const answer = customForm?.answers?.find(
          (answer) => answer.field.name === field.name
        );

        const fieldName = field.name;
        formGroup.addControl(
          fieldName,
          new FormControl(answer?.value, requiredValidator)
        );
      });

      customForm.form = formGroup;
      this.validateForm();
    });
  }

  private async _getFormsByUser(): Promise<void> {
    this.loading = true;
    try {
      this.profileCustomForms = await this._apiFormsService.getFormsByUser(
        this.type
      );

      this.acceptedTerms = this.profileCustomForms?.length > 0;

      this.profileCustomForms.forEach((profileForm) => {
        const form = this.customForms.find(
          (cf) => cf.id === profileForm?.form?.id
        );
        form.answers = profileForm.answers;
      });

      this._buildForms();
    } catch (error) {
      this._toastService.showError(error);
    }
    this.loading = false;
  }

  private async _getFormById(): Promise<void> {
    this.loading = true;

    try {
      const customForm = await this._apiFormsService.getFormsById(this.formId);
      this.customForms.push(customForm);
    } catch (error) {
      this._toastService.showError(error);
    }
    this.loading = false;
  }

  private async _getFormByType(): Promise<void> {
    this.loading = true;

    try {
      this.customForms = await this._apiFormsService.getForms(
        this.type,
        this.notAnswered
      );
    } catch (error) {
      this._toastService.showError(error);
    }
    this.loading = false;
  }

  public async editForm(): Promise<void> {
    if (!this.formsValid || !this.acceptedTerms) {
      return;
    }

    this.isSaving = true;

    const formSubmission = this._buildFormSubmission();

    if (this.disableSave) {
      this.formSavedChanged.emit(formSubmission);
      return;
    }

    try {
      if (!this.profileCustomForms.length) {
        await this._apiFormsService.save(formSubmission);
      } else {
        await this._apiFormsService.edit(formSubmission);
      }

      this._toastService.showSuccess('FEEDBACK_SAVED_DATA_ADD_SUCCESS');
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isSaving = false;
  }

  public async saveForm(): Promise<void> {
    if (!this.formsValid || !this.acceptedTerms) {
      return;
    }

    this.isSaving = true;

    const formSubmission = this._buildFormSubmission();

    if (this.disableSave) {
      this.formSavedChanged.emit(formSubmission);
      return;
    }

    try {
      await this._apiFormsService.save(formSubmission);
      this._toastService.showSuccess('FEEDBACK_SAVED_DATA_ADD_SUCCESS');
      this._commonService.goToNextRoute();
    } catch (error) {
      this._toastService.showError(error);
    }

    this.isSaving = false;
  }

  private _buildFormSubmission(): CustomFormSubmission[] {
    let formSubmission: CustomFormSubmission[];

    if (this.isEditMode && this.profileCustomForms.length) {
      formSubmission = this.customForms.map((customForm) => {
        const fields = customForm.fields.map((field) => {
          return {
            field: field,
            value: customForm.form.controls[field.name].value,
          };
        });

        const profileForm = this.profileCustomForms.find(
          (profileForm) => profileForm.form.id === customForm.id
        );

        const formToPayload: CustomFormSubmission = {
          id: profileForm.id,
          profile: profileForm.profile,
          answers: fields,
          form: {
            id: customForm.id,
          },
          origin: customForm.type,
          originId: customForm.originId,
        };

        if (customForm.originId) {
          formToPayload.form.originId = customForm.originId;
        }

        return formToPayload;
      });
    } else {
      formSubmission = this.customForms.map((customForm) => {
        const fields = customForm.fields.map((field) => {
          return {
            field: field,
            value: customForm.form.controls[field.name].value,
          };
        });

        const formToPayload: CustomFormSubmission = {
          answers: fields,
          form: {
            id: customForm.id,
          },
          origin: customForm.type,
          originId: customForm.originId,
        };

        return formToPayload;
      });
    }

    return formSubmission;
  }
}
