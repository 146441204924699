<mec-modal
  [options]="modalOptions"
  [disableActions]="formGroup.invalid"
  (positiveClick)="addNewCase()"
  (closeClick)="matDialogRef.close(false)"
>
  <div *ngIf="!isLoading" class="program-new-case">
    <form class="program-new-case__form" [formGroup]="formGroup">
      <div class="field__group">
        <label class="field__label">
          {{ 'PROGRAMS.SELECT.PROJECT' | translate }}
        </label>
        <mat-select formControlName="projectId" class="field__input">
          <mat-option *ngFor="let project of projects" [value]="project.token">
            <span [innerHTML]="project.title"></span>
          </mat-option>
        </mat-select>
      </div>

      <div class="field__group">
        <label class="field__label">
          {{ 'PROGRAMS.PACIENT.ID' | translate }}
        </label>
        <input formControlName="patientCode" class="field__input" />
      </div>
    </form>
  </div>

  <!-- Start Skeleton loader -->
  <ng-container *ngIf="isLoading">
    <form class="program-new-case__form" [formGroup]="formGroup">
      <div class="field__group">
        <label
          style="width: 150px"
          class="field__label"
          [medSkeletonLoading]="true"
        >
          {{ 'PROGRAMS.SELECT.PROJECT' | translate }}
        </label>
        <mat-select
          [medSkeletonLoading]="true"
          formControlName="projectId"
          class="field__input"
        >
          <mat-option *ngFor="let project of projects" [value]="project.token">
            <span [innerHTML]="project.title"></span>
          </mat-option>
        </mat-select>
      </div>

      <div class="field__group">
        <label
          style="width: 150px"
          class="field__label"
          [medSkeletonLoading]="true"
        >
          {{ 'PROGRAMS.PACIENT.ID' | translate }}
        </label>
        <input
          [medSkeletonLoading]="true"
          formControlName="patientCode"
          class="field__input"
        />
      </div>
    </form>
  </ng-container>
  <!-- End Skeleton loader -->
</mec-modal>
