import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import {
  LibraryItem,
  LibraryItemList,
} from '../../../common/models/library/library-item';
import { ApiLibraryService } from '../../../services/api/api-library.service';
import { InstanceService } from '../../../services/instance.service';
import { ToastService } from '../../../services/toast.service';
import { LibraryItemComponent } from '../library-item/library-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiCoursesV2Service } from '../../../services/api/api-courses-v2.service';

@Component({
    selector: 'mec-library-list',
    imports: [
        CommonModule,
        TranslateModule,
        LibraryItemComponent,
        InfiniteScrollDirective,
        SkeletonDirective,
    ],
    providers: [ApiCoursesV2Service],
    templateUrl: './library-list.component.html',
    styleUrls: ['./library-list.component.scss']
})
export class LibraryListComponent implements OnInit {
  @Input() public folderPath: string;
  @Input() public stepId: number;
  @Input() public courseSlug: string;

  public libraryItemsList: LibraryItemList = new LibraryItemList();
  public isLoading: boolean;
  public canEdit: boolean;
  public isRowList: boolean;

  constructor(
    private _toastService: ToastService,
    private _instanceService: InstanceService,
    private _apiLibraryService: ApiLibraryService,
    private _apiCoursesV2Courses: ApiCoursesV2Service
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    await this._verifyCanEdit();
    await this.getLibraryFiles();

    this.isLoading = false;
  }

  public async getLibraryFiles(reset?: boolean): Promise<void> {
    this.libraryItemsList.pagination.isLoadingMore = true;

    if (reset) {
      this.libraryItemsList = new LibraryItemList();
    }

    try {
      let itemsToAdd;

      if (this.stepId && this.courseSlug) {
        this.isRowList = true;
        itemsToAdd = await this._getLibraryFromStepCourse();
      } else {
        itemsToAdd = await this._apiLibraryService.getLibraryFiles(
          this.libraryItemsList.pagination
        );
      }

      this.libraryItemsList = PaginationHelper.newHandlePagination<LibraryItem>(
        this.libraryItemsList,
        'libraryItems',
        itemsToAdd
      );
    } catch (error) {
      this._toastService.showError(error);
    }

    this.libraryItemsList.pagination.isLoadingMore = false;
    this.isLoading = false;
  }

  private async _getLibraryFromStepCourse(): Promise<LibraryItem[]> {
    try {
      return await this._apiCoursesV2Courses.getLibraryByStep(
        this.stepId,
        this.courseSlug,
        this.libraryItemsList.pagination
      );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public removeLibraryItem(id: number): void {
    this.libraryItemsList.libraryItems =
      this.libraryItemsList.libraryItems.filter((item) => item.id !== id);
  }

  public async openUploadImageModal(): Promise<void> {
    //todo
    // const resultCloseModal = await this._modalService.openModal(LibraryUploadModalComponent, { folderPath: this.folderPath });
    // if (resultCloseModal.data.hasNewItems) {
    //   this.pagination.page = 0;
    //   await this.getLibraryFiles(null, true);
    // }
  }

  private async _verifyCanEdit(): Promise<void> {
    try {
      this.canEdit = await this._instanceService.canEditLibraryItem();
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
