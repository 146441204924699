import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ModalComponent } from '../../common/modal/modal.component';
import { ApiChainService } from '../../../services/api/api-chain.service';
import { Program } from '../../../common/models/care-monitor/program';
import { ProgramProject } from '../../../common/models/care-monitor/program-project';
import { ToastService } from '@meplis/services';
import { SkeletonDirective } from '../../../directives/skeleton.directive';

@Component({
    selector: 'mec-program-new-case-modal',
    imports: [
        CommonModule,
        FormsModule,
        ModalComponent,
        ReactiveFormsModule,
        TranslateModule,
        MatSelectModule,
        SkeletonDirective,
    ],
    providers: [ApiChainService],
    templateUrl: './program-new-case-modal.component.html',
    styleUrls: ['./program-new-case-modal.component.scss']
})
export class ProgramNewCaseModalComponent implements OnInit {
  public modalOptions: ModalOptions;
  public formGroup: FormGroup;

  public projects: ProgramProject[];
  public projectIdSelected: string;
  public isLoading: boolean;

  constructor(
    public matDialogRef: MatDialogRef<ProgramNewCaseModalComponent>,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _apiChainService: ApiChainService,
    private _toastService: ToastService
  ) {
    this.formGroup = this._formBuilder.group({
      projectId: ['', [Validators.required]],
      patientCode: ['', [Validators.required]],
    });

    this.modalOptions = {
      title: this._translateService.instant('PROGRAMS.ADD_NEW_CASE'),
      closeable: true,
      showSecondaryNegativeButton: false,
      showSecondaryPositiveButton: false,
      showNegativeButton: false,
      positiveButtonLabel: 'SERVICES.POPUP_CONFIRM.BUTTON_CONFIRM',
    };
  }

  public ngOnInit(): void {
    this._getProjects();
  }

  private async _getProjects(): Promise<void> {
    this.isLoading = true;
    try {
      this.projects = await this._apiChainService.getProjects();
    } catch (error) {
      this._toastService.showError(error);
    }
    this.isLoading = false;
  }

  public async addNewCase(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    const projectId = this.formGroup.controls['projectId'].value;
    const patientCode = this.formGroup.controls['patientCode'].value;

    try {
      await this._apiChainService.addNewCase(projectId, patientCode);
      this.matDialogRef.close(projectId);
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
